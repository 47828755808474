export default function postToAPI(dispatch, url, accessToken, actions, data, callback) {
	// Dispatch actions before fetch
	if (Array.isArray(actions.before)) {
		actions.before.map((action) => dispatch(action()));
	}

	if (accessToken && accessToken !== "") url += "?access_token=" + accessToken;

	fetch(url, {
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) {
				throw res.error;
			}
			// Dispatch success actions
			if (Array.isArray(actions.success)) {
				actions.success.map((action) => dispatch(action(res)));
			}

			callback(res);
			return res;
		})
		.catch((error) => {
			// Dispatch error actions
			if (Array.isArray(actions.error)) {
				actions.error.map((action) => dispatch(action(error)));
			}

			callback({ error: error });
			return error;
		});
}

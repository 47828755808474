import { fetchLogsPending, fetchLogsSuccess, fetchLogsError } from "../actions/LogsActions";

const initialState = {
	anyPending: false,
	fetchPending: false,
	deletePending: false,
	editPending: false,
	postPending: false,
	logs: [],
	error: null,
	reloadNeeded: false,
	apiEndpoint: "logEntries",
	loadingActions: {
		before: [fetchLogsPending],
		success: [fetchLogsSuccess],
		error: [fetchLogsError],
	},
};

export const logsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_LOGS_PENDING":
			return {
				...state,
				anyPending: true,
				fetchPending: true,
			};
		case "FETCH_LOGS_SUCCESS":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				logs: action.payload.reverse(),
			};
		case "FETCH_LOGS_ERROR":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				error: action.error,
			};
		case "POST_LOGS_PENDING":
			return {
				...state,
				anyPending: true,
				postPending: true,
			};
		case "POST_LOGS_SUCCESS":
			return {
				...state,
				anyPending: false,
				postPending: false,
				logs: [action.payload].concat(state.logs),
			};
		case "POST_LOGS_ERROR":
			return {
				...state,
				anyPending: false,
				postPending: false,
				error: action.error,
			};
		// case "EDIT_LOGS_PENDING":
		// 	return {
		// 		...state,
		// 		anyPending: true,
		// 		editPending: true,
		// 	};
		// case "EDIT_LOGS_SUCCESS":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		editPending: false,
		// 		logs: state.logs.map((log) => {
		// 			if (log.id === action.payload.id) return action.payload;

		// 			return log;
		// 		}),
		// 	};
		// case "EDIT_LOGS_ERROR":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		editPending: false,
		// 		error: action.error,
		// 	};
		// case "DELETE_LOGS_PENDING":
		// 	return {
		// 		...state,
		// 		anyPending: true,
		// 		deletePending: true,
		// 	};
		// case "DELETE_LOGS_SUCCESS":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		deletePending: false,
		// 		logs: state.logs.filter((log) => log.id !== action.id),
		// 	};
		// case "DELETE_LOGS_ERROR":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		deletePending: false,
		// 		error: action.error,
		// 	};
		// case "LOGS_RELOAD_NEEDED":
		// 	return {
		// 		...state,
		// 		// reloadNeeded: action.reloadNeeded,
		// 	};
		default:
			return state;
	}
};

import React from "react";
import { useSelector } from "react-redux";
import useCalcTaxes from "../hooks/useCalcTaxes";

import useSilkrockBalance from "../hooks/useSilkrockBalance";
import useUserBalance from "../hooks/useUserBalance";
import LoadingSpinner from "./LoadingSpinner";

export default function InfoBar(props) {
	const username = useSelector((state) => state.user.username);

	const expenses = useSelector((state) => state.expenses.fetchPending);
	const projects = useSelector((state) => state.projects.fetchPending);
	const payments = useSelector((state) => state.payments.fetchPending);

	const silkrockBalance = parseFloat(useSilkrockBalance()).toFixed(2);
	const userBalance = parseFloat(useUserBalance(username)).toFixed(2);
	const taxes = parseFloat(useCalcTaxes(new Date())).toFixed(2);

	return (
		<div className="info-bar">
			<div className="silkrock-balance-wrapper">
				silkrock:
				{!expenses && !projects && !payments ? (
					<div className={"silkrock-balance " + (silkrockBalance > 0 ? "green" : "red")}>
						{silkrockBalance} €
					</div>
				) : (
					<LoadingSpinner />
				)}
			</div>
			<div className="taxes-wrapper">
				Steuern {new Date().getFullYear()}:
				{!expenses && !projects && !payments ? (
					<div className={"taxes " + (taxes > 0 ? "red" : "green")}>{taxes} €</div>
				) : (
					<LoadingSpinner />
				)}
			</div>
			<div className="user-balance-wrapper">
				{username[0]?.toUpperCase() + username.slice(1)}:{" "}
				{!expenses && !projects && !payments ? (
					<div className={"user-balance " + (userBalance > 0 ? "green" : "red")}>{userBalance} €</div>
				) : (
					<LoadingSpinner />
				)}
			</div>
		</div>
	);
}

import React from "react";
import { useSelector } from "react-redux";

import { NavLink, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faListAlt,
	faMinusSquare,
	faDollarSign,
	faFlask,
	faDigitalTachograph,
	faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";

import InfoBar from "./InfoBar";
import ProfileButton from "./ProfileButton";
import SilkrockLogo from "./SilkrockLogo";

function NavMenu(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	const loggedIn = useSelector((state) => state.user.loggedIn);

	const matchProjects = useRouteMatch({ path: serverPath + "/projects" });
	const matchPayments = useRouteMatch({ path: serverPath + "/payments" });
	const matchExpenses = useRouteMatch({ path: serverPath + "/expenses" });

	let dropActive = "";
	if (matchPayments || matchExpenses || matchProjects) dropActive = "active";

	return (
		<nav className="nav-menu">
			<ul>
				<NavLink to="/" className="brand-item">
					<SilkrockLogo></SilkrockLogo>
				</NavLink>
				{!loggedIn && (
					<NavLink to={serverPath + "/"} key="login">
						<FontAwesomeIcon icon={faSignInAlt} />
						Login
					</NavLink>
				)}
				{loggedIn && (
					<>
						<NavLink to={serverPath + "/dashboard"} key="dashboard">
							<FontAwesomeIcon icon={faDigitalTachograph} />
							{" Dashboard"}
						</NavLink>
						<div className="dropdown">
							<button href="" className={"dropbtn " + dropActive}>
								<FontAwesomeIcon icon={faDollarSign} />
								{" In & Out"}
							</button>
							<div className="dropdown-content">
								<NavLink to={serverPath + "/projects"} key="projects">
									<FontAwesomeIcon icon={faListAlt} /> {" Projects"}
								</NavLink>
								<NavLink to={serverPath + "/expenses"} key="Expenses">
									<FontAwesomeIcon icon={faMinusSquare} />
									{" Expenses"}
								</NavLink>
								<NavLink to={serverPath + "/payments"} key="Payments">
									<FontAwesomeIcon icon={faDollarSign} />
									{" Payments"}
								</NavLink>
							</div>
						</div>
						<NavLink to={serverPath + "/analytics"} key="analytics">
							<FontAwesomeIcon icon={faFlask} /> {" Analytics"}
						</NavLink>
					</>
				)}
			</ul>

			{loggedIn && (
				<div className="flex-row">
					<InfoBar />
					<ProfileButton />
				</div>
			)}
		</nav>
	);
}

export default NavMenu;

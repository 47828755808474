import React from "react";
import { useSelector } from "react-redux";
import useUserBalance from "../hooks/useUserBalance";

function UserBalances(props) {
	let ceos = useSelector((state) => state.app.ceos);
	ceos[0].balance = useUserBalance(ceos[0].name);
	ceos[1].balance = useUserBalance(ceos[1].name);
	ceos[2].balance = useUserBalance(ceos[2].name);
	ceos[3].balance = useUserBalance(ceos[3].name);

	return (
		<div className="card">
			<table className="table">
				<thead key={0}>
					<tr>
						<td>
							<h3>Nutzer Guthaben</h3>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="user-balance">
								{ceos[0].name[0].toUpperCase() + ceos[0].name.slice(1)}:
								<div className={ceos[0].balance > 0 ? "green" : "red"}>
									{" " + ceos[0].balance.toFixed(2)} €
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="user-balance">
								{ceos[1].name[0].toUpperCase() + ceos[1].name.slice(1)}:
								<div className={ceos[1].balance > 0 ? "green" : "red"}>
									{" " + ceos[1].balance.toFixed(2)} €
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="user-balance">
								{ceos[2].name[0].toUpperCase() + ceos[2].name.slice(1)}:
								<div className={ceos[2].balance > 0 ? "green" : "red"}>
									{" " + ceos[2].balance.toFixed(2)} €
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="user-balance">
								{ceos[3].name[0].toUpperCase() + ceos[3].name.slice(1)}:
								<div className={ceos[3].balance > 0 ? "green" : "red"}>
									{" " + ceos[3].balance.toFixed(2)} €
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default UserBalances;

export const userLoginPending = () => {
	return {
		type: "USER_LOGIN_PENDING",
	};
};

export const userLoginSuccess = (res) => {
	return {
		type: "USER_LOGIN_SUCCESS",
		accessToken: res.id,
		userId: res.userId,
		ttl: res.ttl,
		sessionCreated: res.created,
	};
};

export const userLoginError = (error) => {
	return {
		type: "USER_LOGIN_ERROR",
		error: String(error),
	};
};

export const userLogoutPending = () => {
	return {
		type: "USER_LOGOUT_PENDING",
	};
};

export const userLogoutSuccess = (res) => {
	return {
		type: "USER_LOGOUT_SUCCESS",
	};
};

export const userLogoutError = (error) => {
	return {
		type: "USER_LOGOUT_ERROR",
		error: error,
	};
};

export const LoginFromCookie = (accessToken, ttl, sessionCreated, userId) => {
	return {
		type: "LOGIN_FROM_COOKIE",
		accessToken: accessToken,
		ttl: ttl,
		sessionCreated: sessionCreated,
		userId: userId,
	};
};

export const setLoginCookie = () => {
	return {
		type: "SET_LOGIN_COOKIE",
	};
};
export const setUserBalance = (username, balance) => {
	return {
		type: "SET_USER_BALANCE",
		username: username,
		balance: balance,
	};
};
export const setUsername = (res) => {
	return {
		type: "SET_USERNAME",
		username: res.username,
	};
};

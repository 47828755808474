import config from "../config_production.json";

const initialState = {
	silkrockBalance: 0.0,
	apiUrl: config.apiUrl,
	serverPath: config.serverPath,
	dataNames: ["projects", "expenses", "payments", "settings", "logs", "expensesPeriodic"],
	ceos: [{ name: "arian" }, { name: "tan" }, { name: "kurt" }, { name: "valentin" }],
};

export const appLogicReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_SILKROCK_BALANCE":
			return {
				...state,
				silkrockBalance: action.balance,
			};
		default:
			return state;
	}
};

import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider, useSelector, useDispatch } from "react-redux";

import Cookies from "universal-cookie";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "normalize.css";

import allReducers from "./reducers/index";

import "./styles/styles.scss";

import { LoginFromCookie } from "./actions/UserActions";

import NavMenu from "./components/NavMenu";
import ProjectsTableRow from "./components/ProjectsTableRow";
import { ProjectsInputCard } from "./components/ProjectsInputCard";
import { ExpensesTableRow, ExpensesInputCard } from "./components/ExpensesTableRow";
import { PaymentsTableRow, PaymentsInputCard } from "./components/PaymentsTableRow";
import Settings from "./components/Settings";
import Analytics from "./components/Analytics";
import UserLogin from "./components/UserLogin";
import APILoader from "./components/APILoader";
import TableWrapper from "./components/TableWrapper";
import { getUsername } from "./scripts/userLoginScript";
import Dashboard from "./components/Dashboard";
import LoadingSpinner from "./components/LoadingSpinner";
import { ExpensesPeriodicTableRow } from "./components/ExpensesPeriodicTableRow";

const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

class Kasse extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			ceos: [{ name: "arian" }, { name: "tan" }, { name: "kurt" }, { name: "valentin" }],
		};
	}

	componentDidMount() {
		document.title = "silkrock kasse";
	}

	render() {
		return (
			<Provider store={store}>
				<div className={"appContainer"}>
					<ReactNotification />
					<NavMenu ceos={this.state.ceos} />
					<ViewWrapper ceos={this.state.ceos} />
				</div>
			</Provider>
		);
	}
}

function ViewWrapper(props) {
	const dispatch = useDispatch();

	const loggedIn = useSelector((state) => state.user.loggedIn);
	const serverPath = useSelector((state) => state.app.serverPath);
	const apiUrl = useSelector((state) => state.app.apiUrl);

	const expenses = useSelector((state) => state.expenses.fetchPending);
	const projects = useSelector((state) => state.projects.fetchPending);
	const payments = useSelector((state) => state.payments.fetchPending);

	const cookies = new Cookies();
	const accessToken = cookies.get("accessToken");
	const sessionCreated = cookies.get("sessionCreated");
	const ttl = cookies.get("ttl");
	const userId = cookies.get("userId");

	if (
		!loggedIn &&
		accessToken &&
		sessionCreated &&
		ttl &&
		new Date(Date.parse(sessionCreated)).getTime() + ttl * 1000 > new Date().getTime()
	) {
		dispatch(LoginFromCookie(accessToken, ttl, sessionCreated, userId));
		getUsername(dispatch, apiUrl, accessToken, userId);
	}

	return (
		<main className="viewWrapper">
			{/* If not logged in display Login Prompt (and dont load APILoader) */}
			{loggedIn ? (
				<>
					<APILoader />
					{/* If not all is loaded display LoadingSpinner */}
					{!expenses && !projects && !payments ? (
						<Switch>
							<Route exact path={serverPath + "/:subpath(dashboard|)"}>
								<Dashboard />
							</Route>
							<Route path={serverPath + "/projects"}>
								<TableWrapper
									dataName="projects"
									title="Projekte"
									dataRow={ProjectsTableRow}
									InputCard={<ProjectsInputCard ceos={props.ceos} />}
									fullscreen={true}
								/>
							</Route>
							<Route path={serverPath + "/(expenses|expensesPeriodic)"}>
								<div className="flex-column">
									<TableWrapper
										dataName="expensesPeriodic"
										title="Wiederkehrende Ausgaben"
										dataRow={ExpensesPeriodicTableRow}
										fullscreen={true}
									/>
									<TableWrapper
										dataName="expenses"
										title="Ausgaben"
										dataRow={ExpensesTableRow}
										fullscreen={true}
									/>
								</div>
								<div className="card input-card">
									<ExpensesInputCard />
								</div>
							</Route>
							<Route path={serverPath + "/payments"}>
								<TableWrapper
									dataName="payments"
									title="Auszahlungen"
									dataRow={PaymentsTableRow}
									InputCard={<PaymentsInputCard />}
									fullscreen={true}
								/>
							</Route>
							<Route path={serverPath + "/analytics"} component={Analytics} />
							<Route path={serverPath + "/settings"} component={Settings} />
						</Switch>
					) : (
						<div className="card">
							<LoadingSpinner />
						</div>
					)}
				</>
			) : (
				<UserLogin />
			)}
		</main>
	);
}

ReactDOM.render(
	<BrowserRouter>
		<Kasse />
	</BrowserRouter>,
	document.getElementById("root")
);

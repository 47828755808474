import { store as notifyStore } from "react-notifications-component";

export default function notify(title, message, type = "success", duration = 3000) {
	notifyStore.addNotification({
		title: title,
		message: message,
		type: type,
		insert: "bottom",
		container: "bottom-right",
		animationIn: ["animated", "fadeIn"],
		animationOut: ["animated", "fadeOut"],
		dismiss: {
			duration: duration,
			onScreen: true,
		},
	});
}

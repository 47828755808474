import React from "react";
import useUserTransactions from "../hooks/useUserTransactions";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function UserTxCard(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const userTxs = useUserTransactions(username).reverse();
	const maxLength = props.maxLength;

	return (
		<div className="card user-txs">
			<table className="table">
				<thead>
					<tr key="1">
						<td key="2">
							<NavLink to={serverPath + "/txs"}>
								<h3 className="no-break">Persönliche Einnahmen</h3>
							</NavLink>
						</td>
					</tr>
				</thead>
				<tbody>
					{userTxs.slice(0, maxLength).map((element) => {
						const options = {
							hour: "numeric",
							minute: "numeric",
							year: "numeric",
							month: "numeric",
							day: "numeric",
						};

						return (
							<DataRow
								key={String(element.id) + element.description}
								{...Object.assign({}, element, {
									timestamp: new Date(element.timestamp).toLocaleDateString("de-DE", options),
								})}
							/>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
function DataRow(props) {
	let color;
	props.amount > 0 ? (color = "green") : (color = "red");

	return (
		<tr>
			<td key={props.timestamp}>
				<div className="no-break">{props.timestamp}</div>
			</td>

			<td key={props.description}>
				<div className="no-break">{props.description}</div>
			</td>
			<td key={props.amount.toFixed(2)}>
				<div className={"money bold " + color}>{props.amount.toFixed(2)}€</div>
			</td>
		</tr>
	);
}

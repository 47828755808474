import React from "react";
import TableWrapper from "./TableWrapper";
import ProjectsTableRow from "./ProjectsTableRow";
import LogTableRow from "./LogTableRow";
import ExpensesTableRow from "./ExpensesTableRow";
import PaymentsTableRow from "./PaymentsTableRow";
import UserBalancesCard from "./UserBalancesCard";
import { SalesProfitCard, UserIncomeCard, YTDCard } from "./Analytics";
import UserTxCard from "./UserTxCard";

export default function Dashboard(props) {
	const dashboardCardElms = [
		{ cardComponent: UserTxCard, props: {}, maxLength: 200 },
		{ cardComponent: UserBalancesCard, props: {} },
		{ cardComponent: SalesProfitCard, title: "Umsätze / Profite", props: {} },
		{ cardComponent: UserIncomeCard, title: "Nutzer Einkommen", props: {} },
		{ cardComponent: YTDCard, props: {} },
		{ dataName: "projects", title: "Projekte", dataRow: ProjectsTableRow, needTableWrapper: true, maxLength: 4 },
		{ dataName: "expenses", title: "Ausgaben", dataRow: ExpensesTableRow, needTableWrapper: true, maxLength: 3 },
		{
			dataName: "payments",
			title: "Auszahlungen",
			dataRow: PaymentsTableRow,
			needTableWrapper: true,
			maxLength: 3,
		},
		{ dataName: "logs", title: "Logs", dataRow: LogTableRow, needTableWrapper: true, maxLength: 5 },
	];
	return (
		<div className="dashboard">
			{dashboardCardElms.map((elm) => {
				const CardComponent = elm.cardComponent;

				if (elm?.needTableWrapper) {
					return (
						<TableWrapper
							dataName={elm.dataName}
							dataRow={elm.dataRow}
							title={elm.title}
							maxLength={elm.maxLength}
							fullscreen={false}
							key={elm.dataName}
						/>
					);
				}

				return <CardComponent title={elm.title} key={elm.cardComponent || elm.dataName} />;
			})}
		</div>
	);
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { deleteProjectsPending, deleteProjectsSuccess, deleteProjectsError } from "../actions/ProjectsActions";
import deleteFromAPI from "../scripts/deleteFromAPI";
import notify from "../scripts/notiAndLog";

import CstmTd from "./CstmTd";
import { log } from "../scripts/createLogEntry";

function ProjectsTableRow(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const projects = useSelector((state) => state.projects.projects);

	const projectsApiEndpoint = useSelector((state) => state.projects.apiEndpoint);

	const url = apiUrl + projectsApiEndpoint;

	const actions = {
		before: [deleteProjectsPending],
		success: [deleteProjectsSuccess],
		error: [deleteProjectsError],
	};

	const deleteProject = (id) => {
		const deleteUrl = url + "/" + id;

		const project = projects.find((project) => project.id === id);

		if (!window.confirm('Willst du "' + project.name + '" wirklich löschen?')) return;

		deleteFromAPI(dispatch, deleteUrl, accessToken, actions, { id: id }, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + project.name + '" couldn´t be deleted: ' + res.error?.message, "danger");
				return;
			}

			log(
				logsStoreData,
				"Delete",
				"Project: " +
					project.name +
					" | " +
					project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) +
					" | " +
					project.budget +
					" | Coins: " +
					project.coins.reduce((txt, coin) => txt + "; " + coin.name + ": " + coin.amount, "")
			);

			notify(
				'Deleted  "' + project.name + '"',
				'You have deleted "' + project.name + '" from the Projects!',
				"danger"
			);
		});
	};

	return (
		<tr className="hover-row">
			<CstmTd to={serverPath + "/projects/" + props.id}>
				<time className="no-break">
					{new Date(props.timestamp).toLocaleDateString("de-DE", {
						hour: "numeric",
						minute: "numeric",
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})}
				</time>
			</CstmTd>
			<CstmTd to={serverPath + "/projects/" + props.id}>
				<div className="bold">{props.name}</div>
				<div className="budget-expenses-wrapper">
					<div className="budget no-break">{parseFloat(props.budget).toFixed(2)} €</div>
					<div className="expenses no-break">
						{parseFloat(
							props.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)
						).toFixed(2)}{" "}
						€
					</div>
				</div>
			</CstmTd>
			<CstmTd to={serverPath + "/projects/" + props.id}>
				<div className="profit bold no-break text-right">
					{parseFloat(
						props.budget - props.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)
					).toFixed(2)}
					€
				</div>
			</CstmTd>
			<CstmTd to={serverPath + "/projects/" + props.id}>
				<CoinsCell coins={props.coins} />
			</CstmTd>
			<td className="table-btn-cell table">
				<button
					className="btn btn-edit"
					onClick={() => history.push(serverPath + "/projects/" + props.id + "/edit")}
				>
					<FontAwesomeIcon icon={faEdit} />
				</button>
				<button className="btn btn-delete" onClick={() => deleteProject(props.id)}>
					<FontAwesomeIcon icon={faTrashAlt} />
				</button>
			</td>
		</tr>
	);
}

function CoinsCell(props) {
	if (!props.coins) return null;

	return (
		<div className="project-coins">
			{props.coins.map((coin) => (
				<div key={coin.name} className="coin-element">
					{coin.name[0].toUpperCase()}: {coin.amount}
				</div>
			))}
		</div>
	);
}

export default ProjectsTableRow;

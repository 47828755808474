import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faCheck, faTimes, faReceipt } from "@fortawesome/free-solid-svg-icons";

import {
	deleteExpensesSuccess,
	deleteExpensesError,
	postExpensesSuccess,
	postExpensesError,
	editExpensesPending,
	editExpensesSuccess,
	editExpensesError,
	deleteExpensesPending,
} from "../actions/ExpensesActions";

import deleteFromAPI from "../scripts/deleteFromAPI";
import postToAPI from "../scripts/postToAPI";
import notify from "../scripts/notiAndLog";
import patchToAPI from "../scripts/patchToAPI";
import LoadingSpinner from "./LoadingSpinner";
import { log } from "../scripts/createLogEntry";
import { postExpensesPeriodicError, postExpensesPeriodicSuccess } from "../actions/ExpensesPeriodicActions";
import ReactDatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import Switch from "react-switch";

export function ExpensesTableRow(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	let match = useRouteMatch(serverPath + "/expenses/:expenseID/edit");

	// Check if this row needsexpense be in edit mode
	return (
		<>
			{match && match.params.expenseID === props.id ? <EditExpensesRow {...props} /> : <ExpensesRow {...props} />}
		</>
	);
}

function ExpensesRow(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);

	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const expenses = useSelector((state) => state.expenses.expenses);

	const expensesApiEndpoint = useSelector((state) => state.expenses.apiEndpoint);

	const url = apiUrl + expensesApiEndpoint;

	const actions = {
		before: [deleteExpensesPending],
		success: [deleteExpensesSuccess],
		error: [deleteExpensesError],
	};

	const deleteExpense = (id) => {
		const deleteUrl = url + "/" + id;

		const expense = expenses.find((expense) => expense.id === id);

		if (!window.confirm('Willst du "' + expense.name + '" wirklich löschen?')) return;

		deleteFromAPI(dispatch, deleteUrl, accessToken, actions, { id: id }, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + expense.name + '" couldn´t be added: ' + res.error?.message, "danger");
				return;
			}

			log(logsStoreData, "Delete", "Expense: " + expense.name + " | " + expense.amount);

			notify(
				'Deleted  "' + expense.name + '"',
				'You have deleted "' + expense.name + '" from the Expenses!',
				"danger"
			);
		});
	};

	const color = props.amount < 0 ? "green" : "red";

	return (
		<tr>
			<td>
				<time className="no-break">
					{new Date(props.timestamp).toLocaleDateString("de-DE", {
						hour: "numeric",
						minute: "numeric",
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})}
				</time>
			</td>
			<td>
				<div className="bold">{props.name}</div>
			</td>
			<td>
				<div className={color + " no-break bold text-right"}>{props.amount} €</div>
			</td>
			<td>
				<div className={props.deductable ? "green" : "red"}>
					<FontAwesomeIcon data-tip data-for={"receipt" + props.id} icon={faReceipt} />
					<ReactTooltip id={"receipt" + props.id} effect="solid">
						<span>
							Beleg {props.deductable ? "" : "nicht"} vorhanden / {props.deductable ? "" : "Nicht"}{" "}
							Absetzbar
						</span>
					</ReactTooltip>
				</div>
			</td>
			<td className="table-btn-cell table">
				<Link to={serverPath + "/expenses/" + props.id + "/edit"} className="btn btn-edit">
					<FontAwesomeIcon
						data-tip
						data-for={"edit" + props.id}
						icon={faEdit}
						onClick={() => history.push(serverPath + "/expenses/" + props.id + "/edit")}
					/>
					<ReactTooltip id={"edit" + props.id} effect="solid">
						<span>Bearbeiten</span>
					</ReactTooltip>
				</Link>
				<button className="btn btn-delete" onClick={() => deleteExpense(props.id)}>
					<FontAwesomeIcon data-tip data-for={"delete" + props.id} icon={faTrashAlt} />
					<ReactTooltip id={"delete" + props.id} effect="solid">
						<span>Löschen</span>
					</ReactTooltip>
				</button>
			</td>
		</tr>
	);
}

function EditExpensesRow(props) {
	const [timestamp, setTimestamp] = useState(props.timestamp);
	const [expenseName, setExpenseName] = useState(props.name);
	const [amount, setAmount] = useState(parseFloat(props.amount).toFixed(2));
	const [deductable, setDeductable] = useState(props.deductable);

	const history = useHistory();

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const ExpenseApiEndpoint = useSelector((state) => state.expenses.apiEndpoint);

	const url = apiUrl + ExpenseApiEndpoint;

	const actions = {
		before: [editExpensesPending],
		success: [editExpensesSuccess],
		error: [editExpensesError],
	};

	const editExpense = () => {
		let data = { id: props.id };

		if (amount.trim() === "") setAmount("0.00");

		if (expenseName !== props.name) data.name = expenseName;

		if (Date.parse(timestamp) !== Date.parse(props.timestamp)) data.timestamp = Date.parse(timestamp);

		if (parseFloat(amount) !== props.amount) data.amount = parseFloat(amount);

		if (deductable !== props.deductable) data.deductable = deductable;

		if (Object.keys(data).length <= 1) {
			history.push(serverPath + "/expenses");

			setExpenseName("");
			setAmount("");
			return;
		}

		patchToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + expenseName + '" couldn´t be edited: ' + res.error?.message, "danger");
				return;
			}

			log(
				logsStoreData,
				"Edit",
				"Expense: " +
					(data.timestamp
						? new Date(props.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  }) +
						  "->" +
						  new Date(data.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })
						: new Date(props.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })) +
					" | " +
					(data.name ? props.name + " ->" + data.name : props.name) +
					" | " +
					(data.amount ? props.amount + "->" + data.amount : props.amount) +
					" | " +
					(data.deductable ? props.deductable + "->" + data.deductable : props.deductable)
			);

			notify("Expense Edited", 'You have edited Expense: "' + expenseName + '"');

			history.push(serverPath + "/expenses");

			setExpenseName("");
			setAmount("");
			setDeductable("");
		});
	};

	const editPending = useSelector((state) => state.expenses.editPending);

	if (editPending)
		return (
			<tr>
				<td>
					<LoadingSpinner></LoadingSpinner>
				</td>
			</tr>
		);

	return (
		<tr>
			<td>
				<div className="no-break">
					<ReactDatePicker
						name="rentStartDate"
						selected={new Date(timestamp)}
						dateFormat="dd.MM.yyyy, HH:mm"
						showTimeInput
						onChange={(date) => setTimestamp(date)}
						required
					/>
				</div>
			</td>
			<td>
				<input value={expenseName} onChange={(event) => setExpenseName(event.target.value)} />
			</td>
			<td>
				<input
					className={"text-right no-break"}
					type="number"
					step="0.01"
					value={amount}
					onChange={(event) => setAmount(event.target.value)}
				/>
			</td>
			<td>
				<label htmlFor="deductable">Beleg</label>
				<Switch
					id="deductable"
					name="deductable"
					checked={deductable}
					onChange={(value) => setDeductable(!deductable)}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
					height={15}
					width={35}
				/>
			</td>
			<td className="table-btn-cell table">
				<button className="btn btn-check">
					<FontAwesomeIcon icon={faCheck} onClick={() => editExpense()} />
				</button>
				<button className="btn btn-delete">
					<FontAwesomeIcon icon={faTimes} onClick={() => history.push(serverPath + "/expenses")} />
				</button>
			</td>
		</tr>
	);
}

export function ExpensesInputCard(props) {
	const [expenseName, setExpenseName] = useState("");
	const [amount, setAmount] = useState("");
	const [periodic, setPeriodic] = useState(false);
	const [deductable, setDeductable] = useState(false);
	const [frequency, setFrequency] = useState({ number: "1", text: "month" });
	const [errors] = useState({ expenseName: "", amount: "" });

	const anyPending = useSelector((state) => state.payments.anyPending);

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const ExpensesApiEndpoint = useSelector((state) => state.expenses.apiEndpoint);
	const ExpensesPeriodicApiEndpoint = useSelector((state) => state.expensesPeriodic.apiEndpoint);

	const urlExpenses = apiUrl + ExpensesApiEndpoint;
	const urlExpensesPeriodic = apiUrl + ExpensesPeriodicApiEndpoint;

	const settings = useSelector((state) => state.settings.settings);

	if (!settings) return null;

	const actions = {
		before: [],
		success: [postExpensesSuccess],
		error: [postExpensesError],
	};

	const addExpense = (name, amount) => {
		const timestamp = Date.now();

		if (amount === "") amount = "0.00";

		const data = {
			timestamp: timestamp,
			name: name,
			amount: amount,
			deductable: deductable,
		};

		postToAPI(dispatch, urlExpenses, accessToken, actions, data, (res) => {
			if (res.error) {
				notify(res.error?.name, name + '" couldn´t be added: ' + res.error?.message, "danger");
				return;
			}

			log(logsStoreData, "Add", "Expense: " + data.name + " | " + data.amount);

			notify('Added "' + name + '"', 'You have added "' + name + '"expense the Expenses!', "success");

			setExpenseName("");
			setAmount("");
			setDeductable(false);
		});
	};

	const actionsPeriodic = {
		before: [],
		success: [postExpensesPeriodicSuccess],
		error: [postExpensesPeriodicError],
	};

	const addExpensePeriodic = (name, amount, frequency) => {
		const timestamp = Date.now();

		if (amount === "") amount = "0.00";

		const data = {
			timestamp: timestamp,
			name: name,
			amount: amount,
			deductable: deductable,
			frequency: String(frequency.number) + "|" + frequency.text,
			called: 0,
		};

		postToAPI(dispatch, urlExpensesPeriodic, accessToken, actionsPeriodic, data, (res) => {
			if (res.error) {
				notify(res.error?.name, name + '" couldn´t be added: ' + res.error?.message, "danger");
				return;
			}

			log(logsStoreData, "Add", "ExpensePeriodic: " + data.name + " | " + data.amount + " | " + data.frequency);

			notify('Added "' + name + '"', 'You have added a Periodic Expense: "' + name + '"', "success");

			setExpenseName("");
			setAmount("");
			setDeductable(false);
		});
	};

	function handleChange(event) {
		const { name, value } = event.target;

		switch (name) {
			case "expenseName":
				setExpenseName(value);
				break;
			case "amount":
				setAmount(value.replace(",", "."));
				break;
			case "periodic":
				setPeriodic(!periodic);
				break;
			case "deductable":
				setDeductable(!deductable);
				break;
			case "frequencyNumber":
				setFrequency({ number: value, text: frequency.text });
				break;
			case "frequencyText":
				setFrequency({ number: frequency.number, text: value });
				break;
			default:
				break;
		}
	}

	return (
		<>
			<h5>Neue Ausgabe</h5>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					if (!periodic) {
						addExpense(expenseName.trim(), amount);
					} else {
						addExpensePeriodic(expenseName.trim(), amount, frequency);
					}
				}}
			>
				<label htmlFor="expenseName">Beschreibung</label>
				<input
					name="expenseName"
					className={errors.expenseName.length > 0 ? "input-error" : ""}
					placeholder="Kartoffelschäler fürs Büro"
					value={expenseName}
					onChange={handleChange}
					required
					autoFocus
				></input>

				<label htmlFor="amount">Summe</label>
				<input
					name="amount"
					className={errors.amount.length > 0 ? "input-error" : ""}
					placeholder="13,24"
					type="number"
					step="0.01"
					value={amount}
					onChange={handleChange}
					required
				></input>

				<label htmlFor="deductable"> Beleg vorhanden / Absetzbar?</label>
				<Switch
					id="deductable"
					name="deductable"
					checked={deductable}
					onChange={(value) => handleChange({ target: { name: "deductable", value } })}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
					height={15}
					width={35}
				/>

				<label htmlFor="periodic">Wiederkehrende Ausgabe</label>
				<Switch
					id="periodic"
					name="periodic"
					checked={periodic}
					onChange={(value) => handleChange({ target: { name: "periodic", value } })}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
					height={15}
					width={35}
				/>

				{periodic && (
					<>
						<label htmlFor="frequency">Wiederholungsrate</label>
						<section name="frequency">
							<input
								name="frequencyNumber"
								type="number"
								step="1"
								max="12"
								min="0"
								value={frequency?.number || "1"}
								onChange={handleChange}
								required
							></input>

							<select name="frequencyText" value={frequency?.text || "month"} onChange={handleChange}>
								<option value="day">Tage</option>
								<option value="month">Monate</option>
								<option value="year">Jahre</option>
							</select>
						</section>
					</>
				)}
				<hr />
				<button className="btn btn-primary" type="submit" disabled={anyPending}>
					Ausgeben
				</button>
			</form>
		</>
	);
}

export default ExpensesTableRow;

export const fetchExpensesPeriodicPending = () => {
	return {
		type: "FETCH_EXPENSES_PERIODIC_PENDING",
	};
};

export const fetchExpensesPeriodicSuccess = (expensesPeriodic) => {
	return {
		type: "FETCH_EXPENSES_PERIODIC_SUCCESS",
		payload: expensesPeriodic,
	};
};

export const fetchExpensesPeriodicError = (error) => {
	return {
		type: "FETCH_EXPENSES_PERIODIC_ERROR",
		error: String(error),
	};
};

export const postExpensesPeriodicPending = () => {
	return {
		type: "POST_EXPENSES_PERIODIC_PENDING",
	};
};

export const postExpensesPeriodicSuccess = (expense) => {
	return {
		type: "POST_EXPENSES_PERIODIC_SUCCESS",
		payload: expense,
	};
};

export const postExpensesPeriodicError = (error) => {
	return {
		type: "POST_EXPENSES_PERIODIC_ERROR",
		error: String(error),
	};
};

export const editExpensesPeriodicPending = () => {
	return {
		type: "EDIT_EXPENSES_PERIODIC_PENDING",
	};
};

export const editExpensesPeriodicSuccess = (expense) => {
	return {
		type: "EDIT_EXPENSES_PERIODIC_SUCCESS",
		payload: expense,
	};
};

export const editExpensesPeriodicError = (error) => {
	return {
		type: "EDIT_EXPENSES_PERIODIC_ERROR",
		error: String(error),
	};
};

export const deleteExpensesPeriodicPending = () => {
	return {
		type: "DELETE_EXPENSES_PERIODIC_PENDING",
	};
};

export const deleteExpensesPeriodicSuccess = (res, data) => {
	return {
		type: "DELETE_EXPENSES_PERIODIC_SUCCESS",
		id: data.id,
	};
};

export const deleteExpensesPeriodicError = (error) => {
	return {
		type: "DELETE_EXPENSES_PERIODIC_ERROR",
		error: String(error),
	};
};

export const expensesPeriodicReloadNeeded = () => {
	return {
		type: "EXPENSES_PERIODIC_RELOAD_NEEDED",
	};
};

export const fetchLogsPending = () => {
	return {
		type: "FETCH_LOGS_PENDING",
	};
};

export const fetchLogsSuccess = (logs) => {
	return {
		type: "FETCH_LOGS_SUCCESS",
		payload: logs,
	};
};

export const fetchLogsError = (error) => {
	return {
		type: "FETCH_LOGS_ERROR",
		error: String(error),
	};
};

export const postLogsPending = () => {
	return {
		type: "POST_LOGS_PENDING",
	};
};

export const postLogsSuccess = (log) => {
	return {
		type: "POST_LOGS_SUCCESS",
		payload: log,
	};
};

export const postLogsError = (error) => {
	return {
		type: "POST_LOGS_ERROR",
		error: String(error),
	};
};

// export const editLogsPending = () => {
// 	return {
// 		type: "EDIT_LOGS_PENDING",
// 	};
// };

// export const editLogsSuccess = (log) => {
// 	return {
// 		type: "EDIT_LOGS_SUCCESS",
// 		payload: log,
// 	};
// };

// export const editLogsError = (error) => {
// 	if (typeof error === "object") error = JSON.stringify(error);

// 	return {
// 		type: "POST_LOGS_ERROR",
// 		error: error,
// 	};
// };

// export const deleteLogsPending = () => {
// 	return {
// 		type: "DELETE_LOGS_PENDING",
// 	};
// };

// export const deleteLogsSuccess = (res, data) => {
// 	return {
// 		type: "DELETE_LOGS_SUCCESS",
// 		id: data.id,
// 	};
// };

// export const deleteLogsError = (error) => {
// 	if (typeof error === "object") error = JSON.stringify(error);

// 	return {
// 		type: "DELETE_LOGS_ERROR",
// 		error: error,
// 	};
// };

// export const logsReloadNeeded = () => {
// 	return {
// 		type: "LOGS_RELOAD_NEEDED",
// 	};
// };

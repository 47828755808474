import {
	fetchSettingsError,
	fetchSettingsPending,
	fetchSettingsSuccess,
	settingsReloadNeeded,
} from "../actions/SettingsActions";

const initialState = {
	anyPending: false,
	fetchPending: false,
	deletePending: false,
	editPending: false,
	postPending: false,
	settings: null,
	error: null,
	reloadNeeded: false,
	apiEndpoint: "settings",
	loadingActions: {
		before: [fetchSettingsPending, settingsReloadNeeded],
		success: [fetchSettingsSuccess],
		error: [fetchSettingsError],
	},
};

export const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_SETTINGS_PENDING":
			return {
				...state,
				pending: true,
			};
		case "FETCH_SETTINGS_SUCCESS":
			return {
				...state,
				pending: false,
				error: null,
				settings: Object.assign(action.payload[0], { id: action.payload[0].id }),
			};
		case "FETCH_SETTINGS_ERROR":
			return {
				...state,
				pending: false,
				error: action.error,
			};
		// case "POST_SETTINGS_PENDING":
		// 	return {
		// 		...state,
		// 		anyPending: true,
		// 		postPending: true,
		// 	};
		// case "POST_SETTINGS_SUCCESS":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		postPending: false,
		// 		settings: [action.payload].concat(state.settings),
		// 	};
		// case "POST_SETTINGS_ERROR":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		postPending: false,
		// 		error: action.error,
		// 	};
		case "EDIT_SETTINGS_PENDING":
			return {
				...state,
				anyPending: true,
				editPending: true,
			};
		case "EDIT_SETTINGS_SUCCESS":
			return {
				...state,
				anyPending: false,
				editPending: false,
				settings: action.payload,
			};
		case "EDIT_SETTINGS_ERROR":
			return {
				...state,
				anyPending: false,
				editPending: false,
				error: action.error,
			};
		// case "DELETE_SETTINGS_PENDING":
		// 	return {
		// 		...state,
		// 		anyPending: true,
		// 		deletePending: true,
		// 	};
		// case "DELETE_SETTINGS_SUCCESS":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		deletePending: false,
		// 		settings: state.settings.filter((setting) => setting.id !== action.id),
		// 	};
		// case "DELETE_SETTINGS_ERROR":
		// 	return {
		// 		...state,
		// 		anyPending: false,
		// 		deletePending: false,
		// 		error: action.error,
		// 	};
		case "SETTINGS_RELOAD_NEEDED":
			return {
				...state,
				// reloadNeeded: action.reloadNeeded,
			};
		default:
			return state;
	}
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCoins,
	faPercentage,
	faCalendarAlt,
	faEdit,
	faTrashAlt,
	faReceipt,
	faFileInvoiceDollar,
	faCheck,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import notify from "../scripts/notiAndLog";
import { log } from "../scripts/createLogEntry";
import patchToAPI from "../scripts/patchToAPI";
import { editProjectsError, editProjectsPending, editProjectsSuccess } from "../actions/ProjectsActions";
import ReactDatePicker from "react-datepicker";
import LoadingSpinner from "./LoadingSpinner";
import Switch from "react-switch";
import deleteFromAPI from "../scripts/deleteFromAPI";

export default function ProrjectsDetailCard(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	let match = useRouteMatch(serverPath + "/projects/:projID/edit");

	// Check if this row needs to be in edit mode
	return <>{match ? <EditProjectDetails {...props} /> : <ProjectDetails {...props} />}</>;
}

function ProjectDetails(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	const projects = useSelector((state) => state.projects.projects);
	const project = projects ? projects.find((proj) => proj.id === props.match.params.id) : null;

	const settings = { storagePrice: 0.08 };

	if (!project) return null;

	const storageExpenses = project.storage * settings.storagePrice;
	const projectProfit = parseFloat(
		project.budget -
			project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) -
			storageExpenses
	).toFixed(2);

	const options = {
		hour: "numeric",
		minute: "numeric",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	const time = new Date(project.timestamp).toLocaleDateString("de-DE", options);

	return (
		<div className="card data-card">
			<div className="title-row">
				<h4 className="bold">{project.name}</h4>
				<h5 className="profit">{projectProfit} €</h5>
			</div>
			<div className="rows">
				<div>
					<FontAwesomeIcon icon={faCalendarAlt} /> {time}
				</div>

				<div className="finances">
					<div className="budget-elm">
						Budget:
						<span className="budget profit"> {parseFloat(project.budget).toFixed(2)} €</span>
					</div>
					<div className="expenses-elm">
						Ausgaben:
						<span className="expenses">
							{parseFloat(
								project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)
							).toFixed(2)}
							€
						</span>
					</div>
				</div>
			</div>
			<div className="btn-row">
				<div className="btn btn-edit">
					<Link to={serverPath + "/projects/" + project.id + "/edit"}>
						<FontAwesomeIcon icon={faEdit} />
					</Link>
				</div>
				<div className="btn btn-delete">
					<FontAwesomeIcon icon={faTrashAlt} />
				</div>
			</div>
			<div className="details">
				<span>
					<h6>
						<FontAwesomeIcon icon={faCoins} /> Coins:
					</h6>
					<Coins coins={project.coins} splitPercentage={project.splitPercentage} profit={projectProfit} />
				</span>
				<span>
					<h6>
						<FontAwesomeIcon icon={faPercentage} /> Aufteilung:
					</h6>
					<SplitPercentage splitPercentage={project.splitPercentage} profit={projectProfit} />
				</span>

				<span className="more">
					<h6>
						<FontAwesomeIcon icon={faFileInvoiceDollar} /> Ausgaben:
					</h6>
					<span className="more-elm">
						{typeof project.expenses === "object" && <Expenses expenses={project.expenses} />}
					</span>
				</span>
			</div>
		</div>
	);
}

function Expenses(props) {
	return (
		<div className="expenses-elm">
			{props.expenses.map((expense) => {
				return (
					<div key={expense.name + expense.amount}>
						<div className="expenses-name">{expense.name}</div>
						<div className="expenses-amount red">{expense.amount} €</div>
						<div className={expense.deductable ? "green" : "red"}>
							<FontAwesomeIcon data-tip data-for={"receipt" + props.id} icon={faReceipt} />
							<ReactTooltip id={"receipt" + props.id} effect="solid">
								<span>
									Beleg {expense.deductable ? "" : "nicht"} vorhanden /
									{expense.deductable ? "" : "Nicht"} Absetzbar
								</span>
							</ReactTooltip>
						</div>
						<hr />
					</div>
				);
			})}
		</div>
	);
}

function Coins(props) {
	return (
		<div className="coins">
			{props.coins.map((coin) => {
				return (
					<div key={coin.name} className="coin-elm">
						{coin.name[0].toUpperCase() + coin.name.slice(1)}:
						<div className="coin-amount bold">{coin.amount}%</div>
						<div className="euro-amount no-break">
							{parseFloat((props.splitPercentage.coins * props.profit * coin.amount) / 100).toFixed(2)} €
						</div>
					</div>
				);
			})}
		</div>
	);
}

function SplitPercentage(props) {
	return (
		<div className="percentages-wrapper">
			<div className="percentage-elm">
				CEOs: <div className="percentage">{props.splitPercentage.ceo * 100}%</div>
				<div className="euro-amount no-break bold">
					{parseFloat(props.splitPercentage.ceo * props.profit).toFixed(2)} €
				</div>
			</div>
			<div className="percentage-elm">
				Kasse: <div className="percentage">{props.splitPercentage.kasse * 100}%</div>
				<div className="euro-amount no-break bold">
					{parseFloat(props.splitPercentage.kasse * props.profit).toFixed(2)} €
				</div>
			</div>
			<div className="percentage-elm">
				Coins: <div className="percentage">{props.splitPercentage.coins * 100}%</div>
				<div className="euro-amount no-break bold">
					{parseFloat(props.splitPercentage.coins * props.profit).toFixed(2)} €
				</div>
			</div>
		</div>
	);
}

function EditProjectDetails(props) {
	const projects = useSelector((state) => state.projects.projects);
	const project = projects ? projects.find((proj) => proj.id === props.match.params.id) : null;

	const [timestamp, setTimestamp] = useState(project?.timestamp);
	const [projectName, setProjectName] = useState(project?.name);
	const [budget, setBudget] = useState(parseFloat(project?.budget).toFixed(2));
	const [expenses, setExpenses] = useState(project?.expenses);
	const [storage, setStorage] = useState("");
	const [splitPercentage, setSplitPercentage] = useState(project?.splitPercentage);
	const [coins, setCoins] = useState(
		project?.coins.reduce((obj, coin) => {
			return { ...obj, [coin.name]: coin.amount };
		}, {})
	);

	const history = useHistory();

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const projectsApiEndpoint = useSelector((state) => state.projects.apiEndpoint);

	const url = apiUrl + projectsApiEndpoint;

	const actions = {
		before: [editProjectsPending],
		success: [editProjectsSuccess],
		error: [editProjectsError],
	};

	const editProject = () => {
		let data = { id: project.id };
		let coinsArr = [];

		for (let coinName of Object.keys(coins)) {
			coinsArr.push({ name: coinName, amount: coins[coinName] });
		}

		if (budget.trim() === "") setBudget("0.00");

		if (projectName !== project.name) data.name = projectName;

		if (Date.parse(timestamp) !== Date.parse(project.timestamp)) data.timestamp = Date.parse(timestamp);

		if (parseFloat(budget) !== project.budget) data.budget = parseFloat(budget);

		if (JSON.stringify(expenses) !== JSON.stringify(project.expenses)) data.expenses = expenses;

		if (JSON.stringify(splitPercentage) !== JSON.stringify(project.splitPercentage))
			data.splitPercentage = splitPercentage;

		let coinsChange = false;
		let changedCoins = [];
		for (let coin of coinsArr) {
			if (coin.amount !== project.coins.find((coinf) => coinf.name === coin.name).amount) {
				coinsChange = true;
				changedCoins.push(coin);
			}
		}

		if (coinsChange) data.coins = coinsArr;

		if (Object.keys(data).length <= 1) {
			history.push(serverPath + "/projects/" + project.id);

			setProjectName("");
			setBudget("");
			setExpenses("");
			setStorage("");
			setCoins("");
			return;
		}

		patchToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + projectName + '" couldn´t be edited: ' + res.error?.message, "danger");
				return;
			}

			log(
				logsStoreData,
				"Edit",
				"Project: " +
					(data.timestamp
						? new Date(project.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  }) +
						  "->" +
						  new Date(data.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })
						: new Date(project.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })) +
					" | " +
					(data.name ? project.name + " ->" + data.name : project.name) +
					" | " +
					(data.expenses ? project.expenses + " ->" + data.expenses : project.expenses) +
					" | " +
					(data.budget ? project.budget + "->" + data.budget : project.budget) +
					" | " +
					(data.storage ? project.storage + "->" + data.storage : project.storage) +
					" | Coins: " +
					(data.coins
						? project.coins
								.filter((coinf) => changedCoins.map((coins) => coins.name).includes(coinf.name))
								.reduce((txt, coin) => txt + "; " + coin.name + ": " + coin.amount, "") +
						  "->" +
						  changedCoins.reduce((txt, coin) => txt + "; " + coin.name + ": " + coin.amount, "")
						: "No change")
			);

			notify("Project Edited", 'You have edited Project: "' + projectName + '"', "default");

			history.push(serverPath + "/projects/" + project.id);

			setProjectName("");
			setBudget("");
			setExpenses("");
			setStorage("");
			setCoins("");
		});
	};

	const deleteProject = (id) => {
		const deleteUrl = url + "/" + id;

		const project = projects.find((project) => project.id === id);

		if (!window.confirm('Willst du "' + project.name + '" wirklich löschen?')) return;

		deleteFromAPI(dispatch, deleteUrl, accessToken, actions, { id: id }, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + project.name + '" couldn´t be deleted: ' + res.error?.message);
				return;
			}

			log(
				logsStoreData,
				"Delete",
				"Project: " +
					project.name +
					" | " +
					project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) +
					" | " +
					project.budget +
					" | Coins: " +
					project.coins.reduce((txt, coin) => txt + "; " + coin.name + ": " + coin.amount, "")
			);

			notify(
				'Deleted  "' + project.name + '"',
				'You have deleted "' + project.name + '" from the Projects!',
				"danger"
			);
		});
	};

	const editPending = useSelector((state) => state.projects.editPending);

	if (editPending || !projects)
		return (
			<tr>
				<td>
					<LoadingSpinner></LoadingSpinner>
				</td>
			</tr>
		);

	const profit = budget - expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0);

	return (
		<div className="card data-card">
			<div className="title-row">
				<input className="bold" value={projectName} onChange={(event) => setProjectName(event.target.value)} />
				<h5 className="profit">{parseFloat(profit).toFixed(2)}€</h5>
			</div>
			<div className="rows">
				<div>
					<FontAwesomeIcon icon={faCalendarAlt} />{" "}
					<ReactDatePicker
						name="rentStartDate"
						selected={new Date(timestamp)}
						dateFormat="dd.MM.yyyy, HH:mm"
						showTimeInput
						onChange={(date) => setTimestamp(date)}
						required
					/>
				</div>

				<div className="finances">
					<div className="budget-elm">
						Budget:
						<span className="budget">
							<input
								className="no-break"
								type="number"
								step="0.01"
								value={budget}
								onChange={(event) => setBudget(event.target.value.replace(",", "."))}
							/>
							€
						</span>
					</div>
					<div className="expenses-elm">
						Ausgaben:
						<span className="expenses">
							{parseFloat(
								expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)
							).toFixed(2)}
							€
						</span>
					</div>
				</div>
			</div>
			<div className="btn-row">
				<button onClick={() => editProject()} className="btn btn-check">
					<FontAwesomeIcon icon={faCheck} />
				</button>
				<div className="btn btn-delete" onClick={() => deleteProject(project.id)}>
					<FontAwesomeIcon icon={faTrashAlt} />
				</div>
			</div>
			<div className="details">
				<span>
					<h6>
						<FontAwesomeIcon icon={faCoins} /> Coins:
					</h6>
					<CoinsEdit
						oldCoins={project.coins}
						coins={coins}
						setCoins={setCoins}
						splitPercentage={splitPercentage}
						profit={profit}
					/>
				</span>
				<span className="percentages-wrapper">
					<h6>
						<FontAwesomeIcon icon={faPercentage} /> Aufteilung:
					</h6>
					<SplitPercentageEdit
						splitPercentage={splitPercentage}
						profit={profit}
						setSplitPercentage={setSplitPercentage}
					/>
				</span>

				<span className="more">
					<h6>
						<FontAwesomeIcon icon={faFileInvoiceDollar} /> Ausgaben:
					</h6>
					<span className="more-elm">
						<ExpensesEdit expenses={expenses} setExpenses={setExpenses} />
					</span>
				</span>
			</div>
		</div>
	);
}

function ExpensesEdit(props) {
	function removeExpense(expenses, index) {
		const newExpenses = [...expenses];
		newExpenses.splice(index, 1);
		return newExpenses;
	}

	function handleChange(event) {
		if (event.preventDefault) event.preventDefault();
		const name = event.target.name;
		const value = event.target.value;

		if (name.includes("expense")) {
			const index = name.split("-")[1];
			let newExpenses = [...props.expenses];

			if (name.includes("Amount")) {
				newExpenses[index] = { ...newExpenses[index], amount: value };
			}
			if (name.includes("Name")) {
				newExpenses[index] = { ...newExpenses[index], name: value };
			}
			if (name.includes("Deductable")) {
				newExpenses[index] = { ...newExpenses[index], deductable: !newExpenses[index].deductable };
			}

			props.setExpenses([...newExpenses]);
		}
	}
	return (
		<div className="expenses-elm">
			{props.expenses.map((expense, i) => {
				return (
					<div key={expense.name + expense.amount}>
						{i > 0 && (
							<button
								className="btn btn-delete float-right"
								onClick={() => props.setExpenses([...removeExpense(props.expenses, i)])}
							>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						)}
						<input
							className="expenses-name"
							name={"expenseName-" + i}
							value={expense.name}
							onChange={handleChange}
						/>
						<input
							className="expenses-amount red"
							name={"expenseAmount-" + i}
							value={expense.amount}
							onChange={handleChange}
						/>
						<div>
							<label htmlFor="deductable">Beleg vorhanden / Absetzbar</label>
							<br />
							<Switch
								id="deductable"
								name="deductable"
								checked={expense.deductable}
								onChange={(value) =>
									handleChange({ target: { name: "expenseDeductable-" + i, value } })
								}
								onColor="#86d3ff"
								onHandleColor="#2693e6"
								handleDiameter={20}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
								height={15}
								width={35}
							/>
						</div>
						<hr />
					</div>
				);
			})}
			<div className="text-center">
				<button
					className="btn btn-circle"
					onClick={() => props.setExpenses([...props.expenses, { name: "", amount: "", deductable: false }])}
				>
					+
				</button>
			</div>
			<hr />
		</div>
	);
}

function CoinsEdit(props) {
	return (
		<div className="coins">
			{props.oldCoins.map((coin) => {
				return (
					<div key={coin.name} className="coin-elm">
						<div className="coin-name">{coin.name[0].toUpperCase() + coin.name.slice(1)}:</div>
						<input
							key={coin.name}
							className="input-coins coin-amount"
							type="number"
							step="1"
							value={props.coins[coin.name]}
							onChange={(event) =>
								props.setCoins({ ...props.coins, [coin.name]: event.target.value.replace(",", ".") })
							}
						/>
						<div className="euro-amount no-break">
							{parseFloat(
								(parseFloat(props.splitPercentage.coins) *
									parseFloat(props.profit) *
									parseFloat(props.coins[coin.name])) /
									100
							).toFixed(2)}{" "}
							€
						</div>
					</div>
				);
			})}
		</div>
	);
}

function SplitPercentageEdit(props) {
	return (
		<div>
			<div className="percentage-elm">
				CEOs:
				<input
					className="percentage"
					type="number"
					step="1"
					value={(parseFloat(props.splitPercentage.ceo) * 100).toString()}
					onChange={(event) =>
						props.setSplitPercentage({
							...props.splitPercentage,
							ceo: parseFloat(event.target.value) / 100,
						})
					}
				/>{" "}
				%
				<div className="euro-amount no-break bold">
					{parseFloat(props.splitPercentage.ceo * props.profit).toFixed(2)} €
				</div>
			</div>
			<div className="percentage-elm">
				Kasse:
				<input
					className="percentage"
					type="number"
					step="1"
					value={(parseFloat(props.splitPercentage.kasse) * 100).toString()}
					onChange={(event) =>
						props.setSplitPercentage({
							...props.splitPercentage,
							kasse: parseFloat(event.target.value) / 100,
						})
					}
				/>{" "}
				%
				<div className="euro-amount no-break bold">
					{parseFloat(props.splitPercentage.kasse * props.profit).toFixed(2)} €
				</div>
			</div>

			<div className="percentage-elm">
				Coins:
				<input
					className="percentage"
					type="number"
					step="1"
					value={(parseFloat(props.splitPercentage.coins) * 100).toString()}
					onChange={(event) =>
						props.setSplitPercentage({
							...props.splitPercentage,
							coins: parseFloat(event.target.value) / 100,
						})
					}
				/>{" "}
				%
				<div className="euro-amount no-break bold">
					{parseFloat(props.splitPercentage.coins * props.profit).toFixed(2)} €
				</div>
			</div>
		</div>
	);
}

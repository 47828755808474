import { useSelector } from "react-redux";
import { useMemo } from "react";

export default function useUserBalance(username) {
	const loggedIn = useSelector((state) => state.user.loggedIn);

	// load data, for calulating user balance
	const projects = useSelector((state) => state.projects.projects);
	const payments = useSelector((state) => state.payments.payments);
	const settings = useSelector((state) => state.settings.settings);

	const storagePrice = settings?.storagePrice || null;
	const rentArray = settings?.rent || [];
	const amountOfUsers = settings?.ceos?.length || 4;

	const userBalance = useMemo(() => {
		if (!projects || !payments || !storagePrice || !loggedIn) return 0;

		const userTotalCeoProfits = projects.reduce(
			(total, project) =>
				total +
				((parseFloat(project.budget) -
					project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) -
					parseFloat(project.storage) * parseFloat(storagePrice)) *
					parseFloat(project.splitPercentage.ceo)) /
					amountOfUsers,
			0
		);

		// Compute all accumulated "silkrock-coins" from the projects for this specific user
		const userTotalCoinsProfits = projects.reduce(
			(total, project) =>
				total +
				(parseFloat(project.budget) -
					project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) -
					parseFloat(project.storage) * parseFloat(storagePrice)) *
					((parseFloat(project.splitPercentage.coins) *
						(
							project.coins.find((coin) => coin.name.toLowerCase() === username.toLowerCase()) || {
								amount: 0,
							}
						).amount) /
						100),
			0
		);

		const userTotalPayments = payments.reduce(
			(total, payment) =>
				total + (payment.to.toLowerCase() === username.toLowerCase() ? parseFloat(payment.amount) : 0),
			0
		);

		const totalUserRent = rentArray.reduce((acc, rent) => {
			let userRent = 0;
			if (
				rent.senders.map((sender) => sender.toLowerCase()).includes(username.toLowerCase()) ||
				rent.receivers.map((receiver) => receiver.toLowerCase()).includes(username.toLowerCase())
			) {
				const amountOfUsers = rent.senders.length + rent.receivers.length;
				const monthsBetween = (d1, d2) => {
					var months;
					months = (d2.getFullYear() - d1.getFullYear()) * 12;
					months -= d1.getMonth();
					months += d2.getMonth();
					return months <= 0 ? 0 : months;
				};
				const endDate = rent?.activated ? new Date() : new Date(parseInt(rent.endDate));
				const rentPeriod = monthsBetween(new Date(parseInt(rent.startDate)), endDate);
				userRent = (parseFloat(rent.totalRent) / amountOfUsers) * rentPeriod;

				if (rent.senders.find((name) => name.toLowerCase() === username.toLowerCase())) {
					userRent *= -1;
				}
			}

			return acc + userRent;
		}, 0);

		const userBalance = userTotalCeoProfits + userTotalCoinsProfits - userTotalPayments + totalUserRent;

		return userBalance;
	}, [projects, payments, loggedIn, storagePrice, username, amountOfUsers, rentArray]);

	return userBalance;
}

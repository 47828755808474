export const fetchProjectsPending = () => {
	return {
		type: "FETCH_PROJECTS_PENDING",
	};
};

export const fetchProjectsSuccess = (projects) => {
	return {
		type: "FETCH_PROJECTS_SUCCESS",
		payload: projects,
	};
};

export const fetchProjectsError = (error) => {
	return {
		type: "FETCH_PROJECTS_ERROR",
		error: String(error),
	};
};

export const postProjectsPending = () => {
	return {
		type: "POST_PROJECTS_PENDING",
	};
};

export const postProjectsSuccess = (project) => {
	return {
		type: "POST_PROJECTS_SUCCESS",
		payload: project,
	};
};

export const postProjectsError = (error) => {
	return {
		type: "POST_PROJECTS_ERROR",
		error: String(error),
	};
};

export const editProjectsPending = () => {
	return {
		type: "EDIT_PROJECTS_PENDING",
	};
};

export const editProjectsSuccess = (project) => {
	return {
		type: "EDIT_PROJECTS_SUCCESS",
		payload: project,
	};
};

export const editProjectsError = (error) => {
	return {
		type: "EDIT_PROJECTS_ERROR",
		error: String(error),
	};
};

export const deleteProjectsPending = () => {
	return {
		type: "DELETE_PROJECTS_PENDING",
	};
};

export const deleteProjectsSuccess = (res, data) => {
	return {
		type: "DELETE_PROJECTS_SUCCESS",
		id: data.id,
	};
};

export const deleteProjectsError = (error) => {
	return {
		type: "DELETE_PROJECTS_ERROR",
		error: String(error),
	};
};
export const projectsReloadNeeded = () => {
	return {
		type: "PROJECTS_RELOAD_NEEDED",
	};
};

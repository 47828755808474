import { faCheck, faEdit, faReceipt, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import {
	deleteExpensesPeriodicError,
	deleteExpensesPeriodicPending,
	deleteExpensesPeriodicSuccess,
	editExpensesPeriodicError,
	editExpensesPeriodicPending,
	editExpensesPeriodicSuccess,
} from "../actions/ExpensesPeriodicActions";
import { log } from "../scripts/createLogEntry";
import deleteFromAPI from "../scripts/deleteFromAPI";
import notify from "../scripts/notiAndLog";
import patchToAPI from "../scripts/patchToAPI";
import LoadingSpinner from "./LoadingSpinner";

export function ExpensesPeriodicTableRow(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	let match = useRouteMatch(serverPath + "/expensesPeriodic/:expensePeriodicID/edit");

	// Check if this row needsexpense be in edit mode
	return (
		<>
			{match && match.params.expensePeriodicID === props.id ? (
				<EditExpensesPeriodicRow {...props} />
			) : (
				<ExpensesPeriodicRow {...props} />
			)}
		</>
	);
}

function ExpensesPeriodicRow(props) {
	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);

	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const expensesPeriodic = useSelector((state) => state.expensesPeriodic.expensesPeriodic);

	const expensesPeriodicApiEndpoint = useSelector((state) => state.expensesPeriodic.apiEndpoint);

	const url = apiUrl + expensesPeriodicApiEndpoint;

	const actions = {
		before: [deleteExpensesPeriodicPending],
		success: [deleteExpensesPeriodicSuccess],
		error: [deleteExpensesPeriodicError],
	};

	const deleteExpensePeriodic = (id) => {
		const deleteUrl = url + "/" + id;

		const expensePeriodic = expensesPeriodic.find((expense) => expense.id === id);

		if (!window.confirm('Willst du "' + expensePeriodic.name + '" wirklich löschen?')) return;

		deleteFromAPI(dispatch, deleteUrl, accessToken, actions, { id: id }, (res) => {
			if (res.error) {
				notify(
					res.error?.name,
					'"' + expensePeriodic.name + '" couldn´t be deleted: ' + res.error?.message,
					"danger"
				);
				return;
			}

			log(
				logsStoreData,
				"Delete",
				"Periodic Expense: " +
					expensePeriodic.name +
					" | " +
					expensePeriodic.amount +
					" | " +
					expensePeriodic.frequency +
					" | called: " +
					expensePeriodic.called
			);

			notify(
				'Deleted  "' + expensePeriodic.name + '"',
				'You have deleted "' + expensePeriodic.name + '" from the Periodic Expenses!',
				"danger"
			);
		});
	};

	const color = props.amount < 0 ? "green" : "red";

	return (
		<tr>
			<td>
				<time className="no-break">
					{new Date(props.timestamp).toLocaleDateString("de-DE", {
						hour: "numeric",
						minute: "numeric",
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})}
				</time>
			</td>
			<td>
				<div className="bold">{props.name}</div>
			</td>
			<td>
				<div className={color + " no-break bold text-right"}>{props.amount} €</div>
			</td>
			<td>
				<div className={props.deductable ? "green" : "red"}>
					<FontAwesomeIcon data-tip data-for={"receipt" + props.id} icon={faReceipt} />
					<ReactTooltip id={"receipt" + props.id} effect="solid">
						<span>
							Beleg {props.deductable ? "" : "nicht"} vorhanden / {props.deductable ? "" : "Nicht"}{" "}
							Absetzbar
						</span>
					</ReactTooltip>
				</div>
			</td>
			<td>
				<div className={"no-break"}>{props.frequency.replace("|", " ")}</div>
			</td>
			<td className="table-btn-cell table">
				<Link to={serverPath + "/expensesPeriodic/" + props.id + "/edit"} className="btn btn-edit">
					<FontAwesomeIcon icon={faEdit} />
				</Link>
				<button className="btn btn-delete" onClick={() => deleteExpensePeriodic(props.id)}>
					<FontAwesomeIcon icon={faTrashAlt} />
				</button>
			</td>
		</tr>
	);
}

function EditExpensesPeriodicRow(props) {
	const [timestamp, setTimestamp] = useState(props.timestamp);
	const [expenseName, setExpenseName] = useState(props.name);
	const [amount, setAmount] = useState(parseFloat(props.amount).toFixed(2));
	const [frequencyText, setFrequencyText] = useState(props.frequency.split("|")[1]);
	const [frequencyNumber, setFrequencyNumber] = useState(props.frequency.split("|")[0]);
	const [deductable, setDeductable] = useState(props.deductable);
	// const [called, setCalled] = useState(props.called);

	const history = useHistory();

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const ExpensePeriodicApiEndpoint = useSelector((state) => state.expensesPeriodic.apiEndpoint);

	const url = apiUrl + ExpensePeriodicApiEndpoint;

	const actions = {
		before: [editExpensesPeriodicPending],
		success: [editExpensesPeriodicSuccess],
		error: [editExpensesPeriodicError],
	};

	const editExpensePeriodic = (name, amount, frequencyNumber, frequencyText, deductable) => {
		let data = { id: props.id };

		if (amount.trim() === "") amount = "0.00";

		if (name !== props.name) data.name = name;

		if (deductable !== props.deductable) data.deductable = deductable;

		if (Date.parse(timestamp) !== Date.parse(props.timestamp)) data.timestamp = Date.parse(timestamp);

		if (parseFloat(amount) !== props.amount) data.amount = parseFloat(amount);

		if (frequencyNumber !== props?.frequency?.split("|")[0] || frequencyText !== props?.frequency?.split("|")[1])
			data.frequency = String(frequencyNumber) + " | " + frequencyText;

		if (Object.keys(data).length <= 1) {
			history.push(serverPath + "/expenses");

			setExpenseName("");
			setAmount("");
			setFrequencyText("");
			setFrequencyNumber("");
			return;
		}

		patchToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify("Error", '"' + expenseName + '" couldn´t be edited: ' + res.error, "danger");
				return;
			}

			log(
				logsStoreData,
				"Edit",
				"ExpensePeriodic: " +
					(data.timestamp
						? new Date(props.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  }) +
						  "->" +
						  new Date(data.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })
						: new Date(props.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })) +
					" | " +
					(data.name ? props.name + " ->" + data.name : props.name) +
					" | " +
					(data.amount ? props.amount + "->" + data.amount : props.amount) +
					" | " +
					(data.deductable ? props.deductable + "->" + data.deductable : props.deductable)
			);

			notify("Expense Periodic Edited", 'You have edited Expense: "' + expenseName + '"');

			history.push(serverPath + "/expenses");

			setExpenseName("");
			setAmount("");
			setFrequencyNumber("");
			setFrequencyText("");
		});
	};

	const editPending = useSelector((state) => state.expenses.editPending);

	if (editPending)
		return (
			<tr>
				<td>
					<LoadingSpinner></LoadingSpinner>
				</td>
			</tr>
		);

	return (
		<tr>
			<td>
				<div className="no-break">
					<ReactDatePicker
						name="rentStartDate"
						selected={new Date(timestamp)}
						dateFormat="dd.MM.yyyy, HH:mm"
						showTimeInput
						onChange={(date) => setTimestamp(date)}
						required
					/>
				</div>
			</td>
			<td>
				<input value={expenseName} onChange={(event) => setExpenseName(event.target.value)} />
			</td>
			<td>
				<input
					className={"text-right no-break"}
					type="number"
					step="0.01"
					value={amount}
					onChange={(event) => setAmount(event.target.value)}
				/>
			</td>
			<td className="">
				<label htmlFor="deductable">Beleg</label>
				<Switch
					id="deductable"
					name="deductable"
					checked={deductable}
					onChange={(value) => setDeductable(!deductable)}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
					height={15}
					width={35}
				/>
			</td>
			<td>
				<section className="flex-row" name="frequency">
					<input
						name="frequencyNumber"
						type="number"
						step="1"
						max="12"
						min="0"
						value={String(frequencyNumber) || "1"}
						onChange={(event) => setFrequencyNumber(event.target.value)}
						required
					></input>

					<select
						name="frequencyText"
						value={frequencyText || "month"}
						onChange={(event) => setFrequencyText(event.target.value)}
					>
						<option value="day">Tage</option>
						<option value="month">Monate</option>
						<option value="year">Jahre</option>
					</select>
				</section>
			</td>
			<td className="table-btn-cell table">
				<button className="btn btn-check">
					<FontAwesomeIcon
						icon={faCheck}
						onClick={() =>
							editExpensePeriodic(expenseName, amount, frequencyNumber, frequencyText, deductable)
						}
					/>
				</button>
				<button className="btn btn-delete">
					<FontAwesomeIcon icon={faTimes} onClick={() => history.push(serverPath + "/expenses")} />
				</button>
			</td>
		</tr>
	);
}

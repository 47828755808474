import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faCheck, faTimes, faReceipt } from "@fortawesome/free-solid-svg-icons";

import {
	postPaymentsError,
	postPaymentsSuccess,
	deletePaymentsSuccess,
	deletePaymentsError,
	postPaymentsPending,
	deletePaymentsPending,
	editPaymentsPending,
	editPaymentsSuccess,
	editPaymentsError,
} from "../actions/PaymentsActions";
import deleteFromAPI from "../scripts/deleteFromAPI";

import postToAPI from "../scripts/postToAPI";
import notify from "../scripts/notiAndLog";
import LoadingSpinner from "./LoadingSpinner";
import patchToAPI from "../scripts/patchToAPI";
import { log } from "../scripts/createLogEntry";
import ReactDatePicker from "react-datepicker";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";

export function PaymentsTableRow(props) {
	const serverPath = useSelector((state) => state.app.serverPath);
	let match = useRouteMatch(serverPath + "/payments/:paymentID/edit");

	// Check if this row needs to be in edit mode
	return (
		<>
			{match && match.params.paymentID === props.id ? <EditPaymentsRow {...props} /> : <PaymentsRow {...props} />}
		</>
	);
}

function PaymentsRow(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const payments = useSelector((state) => state.payments.payments);
	const paymentsApiEndpoint = useSelector((state) => state.payments.apiEndpoint);

	const paymentsUrl = apiUrl + paymentsApiEndpoint;

	const deletePayment = (id) => {
		const deleteUrl = paymentsUrl + "/" + id;

		const payment = payments.find((payment) => payment.id === id);

		if (!window.confirm('Willst du "' + payment.description + '" wirklich löschen?')) return;

		const actions = {
			before: [deletePaymentsPending],
			success: [deletePaymentsSuccess],
			error: [deletePaymentsError],
		};

		deleteFromAPI(dispatch, deleteUrl, accessToken, actions, { id: id }, (res) => {
			if (res.error) {
				notify(
					res.error?.name,
					payment.to + ": " + payment.description + " couldn´t be deleted: " + res.error?.message
				);
				return;
			}

			log(
				logsStoreData,
				"Delete",
				"Payment: " + payment.to + " | " + payment.description + " | " + payment.amount
			);

			notify(
				'Deleted  "' + payment.description + '"',
				'You have deleted "' + payment.to + ": " + payment.description + '" from the Payments!',
				"danger"
			);
		});
	};

	const color = props.amount > 0 ? "profit" : "expenses";

	return (
		<tr>
			<td>
				<time className="no-break">
					{new Date(props.timestamp).toLocaleDateString("de-DE", {
						hour: "numeric",
						minute: "numeric",
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})}
				</time>
			</td>
			<td>
				<div className="bold">{props.to[0].toUpperCase() + props.to.slice(1)}</div>
			</td>
			<td>
				<div className="">{props.description}</div>
			</td>
			<td>
				<div className={"text-right no-break " + color}>{props.amount} €</div>
			</td>
			<td>
				<div className={props.deductable ? "green" : "red"}>
					<FontAwesomeIcon data-tip data-for={"receipt" + props.id} icon={faReceipt} />
					<ReactTooltip id={"receipt" + props.id} effect="solid">
						<span>
							Beleg {props.deductable ? "" : "nicht"} vorhanden / {props.deductable ? "" : "Nicht"}{" "}
							Absetzbar
						</span>
					</ReactTooltip>
				</div>
			</td>
			<td className="table-btn-cell table">
				<Link to={serverPath + "/payments/" + props.id + "/edit"} className="btn btn-edit">
					<FontAwesomeIcon
						icon={faEdit}
						onClick={() => history.push(serverPath + "/payments/" + props.id + "/edit")}
					/>
				</Link>
				<button className="btn btn-delete" onClick={() => deletePayment(props.id)}>
					<FontAwesomeIcon icon={faTrashAlt} />
				</button>
			</td>
		</tr>
	);
}

function EditPaymentsRow(props) {
	const [timestamp, setTimestamp] = useState(props.timestamp);
	const [paymentName, setPaymentName] = useState(props.description);
	const [to, setTo] = useState(props.to);
	const [amount, setAmount] = useState(parseFloat(props.amount).toFixed(2));
	const [deductable, setDeductable] = useState(props.deductable);

	const history = useHistory();

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const serverPath = useSelector((state) => state.app.serverPath);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const paymentsApiEndpoint = useSelector((state) => state.payments.apiEndpoint);

	const url = apiUrl + paymentsApiEndpoint;

	const actions = {
		before: [editPaymentsPending],
		success: [editPaymentsSuccess],
		error: [editPaymentsError],
	};

	const editPayment = () => {
		let data = { id: props.id };

		if (amount.trim() === "") amount = "0.00";

		if (paymentName !== props.description) data.description = paymentName;

		if (to !== props.to) data.to = to;

		if (Date.parse(new Date(timestamp)) !== Date.parse(new Date(props.timestamp)))
			data.timestamp = Date.parse(new Date(timestamp));

		if (parseFloat(amount) !== props.amount) data.amount = parseFloat(amount);

		if (deductable !== props.deductable) data.deductable = deductable;

		if (Object.keys(data).length <= 1) {
			history.push(serverPath + "/payments");

			setPaymentName("");
			setAmount("");
			setTo("");
			return;
		}

		patchToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + paymentName + '" couldn´t be edited: ' + res.error?.message, "danger");
				return;
			}

			log(
				logsStoreData,
				"Edit",
				"Payment: " +
					(data.timestamp
						? new Date(props.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  }) +
						  "->" +
						  new Date(data.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })
						: new Date(props.timestamp).toLocaleDateString("de-DE", {
								hour: "numeric",
								minute: "numeric",
								year: "numeric",
								month: "numeric",
								day: "numeric",
						  })) +
					" | " +
					(data.to ? props.to + " ->" + data.to : props.to) +
					" | " +
					(data.description ? props.description + " ->" + data.description : props.description) +
					" | " +
					(data.amount ? props.amount + "->" + data.amount : props.amount)
			);

			notify("Payment Edited", 'You have edited Payment: "' + paymentName + '"', "default");

			history.push(serverPath + "/payments");

			setPaymentName("");
			setAmount("");
			setTo("");
		});
	};

	const editPending = useSelector((state) => state.payments.editPending);

	if (editPending)
		return (
			<tr>
				<td>
					<LoadingSpinner></LoadingSpinner>
				</td>
			</tr>
		);

	return (
		<tr>
			<td>
				<div className="no-break">
					<ReactDatePicker
						name="rentStartDate"
						selected={new Date(timestamp)}
						dateFormat="dd.MM.yyyy, HH:mm"
						showTimeInput
						onChange={(date) => setTimestamp(date)}
						required
					/>
				</div>
			</td>
			<td>
				<input value={to} onChange={(event) => setTo(event.target.value)} />
			</td>
			<td>
				<input value={paymentName} onChange={(event) => setPaymentName(event.target.value)} />
			</td>
			<td>
				<input
					className={"text-right no-break"}
					type="number"
					step="0.01"
					value={amount}
					onChange={(event) => setAmount(event.target.value)}
				/>
			</td>
			<td>
				<label htmlFor="deductable">Beleg</label>
				<Switch
					id="deductable"
					name="deductable"
					checked={deductable}
					onChange={(value) => setDeductable(!deductable)}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
					height={15}
					width={35}
				/>
			</td>
			<td className="table-btn-cell table">
				<button className="btn btn-check">
					<FontAwesomeIcon icon={faCheck} onClick={() => editPayment()} />
				</button>
				<button className="btn btn-delete">
					<FontAwesomeIcon icon={faTimes} onClick={() => history.push(serverPath + "/payments")} />
				</button>
			</td>
		</tr>
	);
}

export function PaymentsInputCard(props) {
	const [paymentName, setPaymentName] = useState("");
	const [amount, setAmount] = useState("");
	const [to, setTo] = useState("");
	const [deductable, setDeductable] = useState(false);
	const [errors, setErrors] = useState({ paymentName: "", amount: "", to: "" });

	const dispatch = useDispatch();

	const ceos = useSelector((state) => state.app.ceos);

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const anyPending = useSelector((state) => state.payments.anyPending);
	const paymentsApiEndpoint = useSelector((state) => state.payments.apiEndpoint);

	const url = apiUrl + paymentsApiEndpoint;

	const settings = useSelector((state) => state.settings.settings);

	if (!settings) return null;

	const actions = {
		before: [postPaymentsPending],
		success: [postPaymentsSuccess],
		error: [postPaymentsError],
	};

	const addPayment = (description, amount, to, deductable) => {
		const timestamp = Date.now();

		const data = {
			timestamp: timestamp,
			description: description,
			amount: amount,
			to: to,
			deductable: deductable,
		};

		postToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify(
					res.error?.name,
					to + ": " + description + '" couldn´t be added: ' + res.error?.message,
					"danger"
				);
				return;
			}

			log(logsStoreData, "Add", "Payment: " + data.to + " | " + data.description + " | " + data.amount);

			notify(
				'Added "' + to + ": " + description + '"',
				'You have added "' + description + '" to the Payments!',
				"success"
			);

			setPaymentName("");
			setAmount("");
			setTo("");
			setDeductable(false);
		});
	};

	function handleChange(event) {
		if (event.preventDefault) event.preventDefault();
		const { name, value } = event.target;

		switch (name) {
			case "paymentName":
				setPaymentName(value);
				break;
			case "amount":
				setAmount(value.trim().replace(",", "."));
				break;
			case "deductable":
				setDeductable(!deductable);
				break;
			case "to":
				setTo(value);
				setErrors({
					...errors,
					to: !ceos.map((ceo) => ceo.name).includes(value.trim().toLowerCase())
						? "Empfänger existiert nicht"
						: "",
				});
				break;
			default:
				break;
		}
	}

	const validateForm = (errors) => {
		let valid = true;

		Object.values(errors).forEach(
			// if we have an error string set valid to false
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	};

	return (
		<>
			<h5>Neuer Cash Out</h5>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					addPayment(paymentName.trim(), amount, to.trim(), deductable);
				}}
			>
				{errors.to.length > 0 && <span className="form-error">{errors.to}</span>}
				<label htmlFor="to">An</label>
				<input
					name="to"
					className={errors.to.length > 0 ? "input-error" : ""}
					placeholder={username[0].toUpperCase() + username.slice(1)}
					type="text"
					value={to}
					onChange={handleChange}
					required
					autoFocus
				></input>

				<label htmlFor="amount">Betrag</label>
				<input
					name="amount"
					className={errors.amount.length > 0 ? "input-error" : ""}
					placeholder="133,32"
					type="number"
					step="0.01"
					value={amount}
					onChange={handleChange}
					required
				></input>

				<label htmlFor="paymentName">Beschreibung</label>
				<input
					name="paymentName"
					className={errors.paymentName.length > 0 ? "input-error" : ""}
					placeholder="Bonuszahlung"
					type="text"
					value={paymentName}
					onChange={handleChange}
					required
				></input>

				<label htmlFor="deductable"> Mit Rechnung?</label>
				<Switch
					id="deductable"
					name="deductable"
					checked={deductable}
					onChange={(value) => handleChange({ target: { name: "deductable", value } })}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
					height={15}
					width={35}
				/>
				<hr />
				<button disabled={anyPending || !validateForm(errors)} className="btn btn-primary" type="submit">
					Cash'em
				</button>
			</form>
		</>
	);
}

export default PaymentsTableRow;

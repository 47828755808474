import React from "react";

function LogTableRow(props) {
	let color;
	if (props.action === "Add") color = "green";
	if (props.action === "Edit") color = "blue";
	if (props.action === "Delete") color = "red";

	const highlightChange = (descr) => {
		return descr.split("|").map((piece, index) => {
			return piece.includes("->") ? (
				<div className="bold mark" key={descr + index + piece}>
					{piece + " | "}
				</div>
			) : (
				<div key={descr + index + piece}>
					<div className="log-edit-piece">{piece + " |  "}</div>
				</div>
			);
		});
	};

	return (
		<tr className={color}>
			<td>
				<div className="no-break">
					{new Date(props.timestamp).toLocaleDateString("de-DE", {
						hour: "numeric",
						minute: "numeric",
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})}
				</div>
			</td>
			<td>
				<div className="bold">{props.user[0].toUpperCase() + props.user.slice(1, props.user.length)}</div>
			</td>
			<td>
				<div className="">{props.action}</div>
			</td>
			<td>
				<div className="small no-break">
					{props.action.includes("Edit") ? highlightChange(props.description) : props.description}
				</div>
			</td>
		</tr>
	);
}
export default LogTableRow;

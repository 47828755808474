export const fetchSettingsPending = () => {
	return {
		type: "FETCH_SETTINGS_PENDING",
	};
};

export const fetchSettingsSuccess = (settings) => {
	return {
		type: "FETCH_SETTINGS_SUCCESS",
		payload: settings,
	};
};

export const fetchSettingsError = (error) => {
	if (typeof error === "object") error = JSON.stringify(error);

	return {
		type: "FETCH_SETTINGS_ERROR",
		error: error,
	};
};

export const postSettingsPending = () => {
	return {
		type: "POST_SETTINGS_PENDING",
	};
};

export const postSettingsSuccess = (setting) => {
	return {
		type: "POST_SETTINGS_SUCCESS",
		payload: setting,
	};
};

export const postSettingsError = (error) => {
	if (typeof error === "object") error = JSON.stringify(error);

	return {
		type: "POST_SETTINGS_ERROR",
		error: error,
	};
};

export const editSettingsPending = () => {
	return {
		type: "EDIT_SETTINGS_PENDING",
	};
};

export const editSettingsSuccess = (setting) => {
	return {
		type: "EDIT_SETTINGS_SUCCESS",
		payload: setting,
	};
};

export const editSettingsError = (error) => {
	return {
		type: "EDIT_SETTINGS_ERROR",
		error: String(error),
	};
};

export const deleteSettingsPending = () => {
	return {
		type: "DELETE_SETTINGS_PENDING",
	};
};

export const deleteSettingsSuccess = (res, data) => {
	return {
		type: "DELETE_SETTINGS_SUCCESS",
		id: data.id,
	};
};

export const deleteSettingsError = (error) => {
	if (typeof error === "object") error = JSON.stringify(error);

	return {
		type: "DELETE_SETTINGS_ERROR",
		error: error,
	};
};

export const settingsReloadNeeded = () => {
	return {
		type: "SETTINGS_RELOAD_NEEDED",
	};
};

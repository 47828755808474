import React from "react";
import { Link } from "react-router-dom";

export default function CstmTd(props) {
	// Conditionally wrapping content into a link
	const ContentTag = props.to ? Link : "div";

	return (
		<td>
			<ContentTag
				ref={(el) => {
					if (props.childRef) props.childRef(el);
				}}
				to={props.to}
				className={props.className}
			>
				{props.children}
			</ContentTag>
		</td>
	);
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { UserLogout } from "../scripts/userLoginScript";

export default function LogoutButton(props) {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.user.accessToken);
	const apiUrl = useSelector((state) => state.app.apiUrl);

	return (
		<div className={props.className} onClick={() => UserLogout(dispatch, apiUrl, accessToken)}>
			<FontAwesomeIcon icon={faSignOutAlt} /> {"   "}
			{props.children}
		</div>
	);
}

const initialState = {
	pending: false,
	error: null,

	loggedIn: false,
	userId: "",
	ttl: 0,
	sessionCreated: 0,
	accessToken: "",
	username: "",
	userBalances: {},
};

export const userReducers = (state = initialState, action) => {
	switch (action.type) {
		case "USER_LOGIN_PENDING":
			return {
				...state,
				pending: true,
			};
		case "USER_LOGIN_SUCCESS":
			return {
				...state,
				pending: false,
				loggedIn: true,
				accessToken: action.accessToken,
				userId: action.userId,
				ttl: action.ttl,
				sessionCreated: action.sessionCreated,
			};
		case "USER_LOGIN_ERROR":
			return {
				...state,
				pending: false,
				error: action.error,
			};
		case "USER_LOGOUT_PENDING":
			return {
				...state,
				pending: true,
			};
		case "USER_LOGOUT_SUCCESS":
			return {
				...state,
				pending: false,
				loggedIn: false,
			};
		case "USER_LOGOUT_ERROR":
			return {
				...state,
				pending: false,
				error: action.error,
			};
		case "LOGIN_FROM_COOKIE":
			return {
				...state,
				loggedIn: true,
				accessToken: action.accessToken,
				ttl: action.ttl,
				sessionCreated: action.sessionCreated,
				userId: action.userId,
			};
		case "SET_LOGIN_COOKIE":
			return {
				...state,
			};
		case "SET_USER_BALANCE":
			return {
				...state,
				userBalances: { ...state.userBalances, [action.username]: action.balance },
			};
		case "SET_USERNAME":
			return {
				...state,
				username: action.username,
			};
		default:
			return state;
	}
};

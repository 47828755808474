import postToAPI from "./postToAPI";
import { postLogsPending, postLogsError, postLogsSuccess } from "../actions/LogsActions";

export function log(storeData, action, description) {
	createLogEntry(
		storeData.dispatch,
		storeData.url,
		storeData.accessToken,
		Date.now(),
		storeData.username,
		action,
		description
	);
}

function createLogEntry(dispatch, url, accessToken, timestamp, user, action, description) {
	const actions = {
		before: [postLogsPending],
		error: [postLogsError],
		success: [postLogsSuccess],
	};

	const data = {
		timestamp: timestamp,
		action: action.trim(),
		user: user.trim(),
		description: description.trim(),
	};

	postToAPI(dispatch, url, accessToken, actions, data, (res) => null);
}

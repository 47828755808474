export const fetchPaymentsPending = () => {
	return {
		type: "FETCH_PAYMENTS_PENDING",
	};
};

export const fetchPaymentsSuccess = (payments) => {
	return {
		type: "FETCH_PAYMENTS_SUCCESS",
		payload: payments,
	};
};

export const fetchPaymentsError = (error) => {
	return {
		type: "FETCH_PAYMENTS_ERROR",
		error: String(error),
	};
};

export const postPaymentsPending = () => {
	return {
		type: "POST_PAYMENTS_PENDING",
	};
};

export const postPaymentsSuccess = (payment) => {
	return {
		type: "POST_PAYMENTS_SUCCESS",
		payload: payment,
	};
};

export const postPaymentsError = (error) => {
	return {
		type: "POST_PAYMENTS_ERROR",
		error: String(error),
	};
};

export const editPaymentsPending = () => {
	return {
		type: "EDIT_PAYMENTS_PENDING",
	};
};

export const editPaymentsSuccess = (payment) => {
	return {
		type: "EDIT_PAYMENTS_SUCCESS",
		payload: payment,
	};
};

export const editPaymentsError = (error) => {
	return {
		type: "POST_PAYMENTS_ERROR",
		error: String(error),
	};
};

export const deletePaymentsPending = () => {
	return {
		type: "DELETE_PAYMENTS_PENDING",
	};
};

export const deletePaymentsSuccess = (res, data) => {
	return {
		type: "DELETE_PAYMENTS_SUCCESS",
		id: data.id,
	};
};

export const deletePaymentsError = (error) => {
	return {
		type: "DELETE_PAYMENTS_ERROR",
		error: String(error),
	};
};

export const paymentsReloadNeeded = () => {
	return {
		type: "PAYMENTS_RELOAD_NEEDED",
	};
};

import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../scripts/notiAndLog";
import postToAPI from "../scripts/postToAPI";
import { log } from "../scripts/createLogEntry";
import { postExpensesError, postExpensesSuccess } from "../actions/ExpensesActions";
import {
	editExpensesPeriodicError,
	editExpensesPeriodicPending,
	editExpensesPeriodicSuccess,
} from "../actions/ExpensesPeriodicActions";
import patchToAPI from "../scripts/patchToAPI";

export default function useSilkrockBalance() {
	const loggedIn = useSelector((state) => state.user.loggedIn);

	// load data, for calulating silkrock balance
	const projects = useSelector((state) => state.projects.projects);
	const expenses = useSelector((state) => state.expenses.expenses);
	const expensesPeriodic = useSelector((state) => state.expensesPeriodic?.expensesPeriodic) || [];
	const payments = useSelector((state) => state.payments.payments);
	const settings = useSelector((state) => state.settings.settings);
	const rentArray = settings?.rent || [];

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const ExpensesApiEndpoint = useSelector((state) => state.expenses.apiEndpoint);

	const urlExpenses = apiUrl + ExpensesApiEndpoint;

	const storagePrice = settings ? settings.storagePrice : null;

	const silkrockBalance = useMemo(() => {
		if (!projects || !expenses || !payments || !storagePrice || !loggedIn) return 0;

		const totalProfits = projects.reduce(
			(total, project) =>
				total +
				(parseFloat(project.budget) -
					project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) -
					parseFloat(project.storage) * parseFloat(storagePrice)) *
					parseFloat(project.splitPercentage.kasse),
			0
		);

		const totalStorageCosts = projects.reduce(
			(total, project) => total + parseFloat(project.storage) * parseFloat(storagePrice),
			0
		);

		const totalExpenses = expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0);

		const totalKasseRent = rentArray.reduce((acc, rent) => {
			let kasseRent = 0;
			if (rent.senders.includes("kasse") && rent.senders.length === 1) {
				const monthsBetween = (d1, d2) => {
					var months;
					months = (d2.getFullYear() - d1.getFullYear()) * 12;
					months -= d1.getMonth();
					months += d2.getMonth();
					return months <= 0 ? 0 : months;
				};
				const endDate = rent?.activated ? new Date() : new Date(parseInt(rent.endDate));
				const rentPeriod = monthsBetween(new Date(parseInt(rent.startDate)), endDate);
				kasseRent = parseFloat(rent.totalRent) * rentPeriod * -1;
			}
			return acc + kasseRent;
		}, 0);

		const totalBalance = parseFloat(totalProfits - totalExpenses + totalStorageCosts + totalKasseRent).toFixed(2);

		return totalBalance;
	}, [projects, expenses, payments, loggedIn, storagePrice, rentArray]);

	const ExpensePeriodicApiEndpoint = useSelector((state) => state.expensesPeriodic.apiEndpoint);

	const urlExpensesPeriodic = apiUrl + ExpensePeriodicApiEndpoint;

	useEffect(() => {
		const logsStoreData = {
			dispatch: dispatch,
			accessToken: accessToken,
			url: logsUrl,
			username: "System",
		};

		const actionsPeriodic = {
			before: [editExpensesPeriodicPending],
			success: [editExpensesPeriodicSuccess],
			error: [editExpensesPeriodicError],
		};

		const monthsBetween = (d1, d2) => {
			var months;
			months = (d2.getFullYear() - d1.getFullYear()) * 12;
			months -= d1.getMonth();
			months += d2.getMonth();
			return months <= 0 ? 0 : months;
		};
		const yearsBetween = (d1, d2) => {
			const msBetween = Date.parse(new Date(d2)) - Date.parse(new Date(d1));
			const years = msBetween / 1000 / 60 / 60 / 24 / 365;
			return Math.floor(years);
		};

		const daysBetween = (d1, d2) => {
			const msBetween = Date.parse(new Date(d2)) - Date.parse(new Date(d1));
			const days = msBetween / 1000 / 60 / 60 / 24;
			return Math.floor(days);
		};

		const actions = {
			before: [],
			success: [postExpensesSuccess],
			error: [postExpensesError],
		};

		for (let expense of expensesPeriodic) {
			const editExpensePeriodicCalled = (id, called) => {
				const data = { id: id, called: called };
				patchToAPI(dispatch, urlExpensesPeriodic, accessToken, actionsPeriodic, data, (res) => {});
			};

			const addExpense = (name, amount, deductable, called) => {
				const timestamp = Date.now();

				if (amount === "") amount = "0.00";

				const data = {
					timestamp: timestamp,
					name: name,
					amount: amount,
					deductable: deductable,
				};

				postToAPI(dispatch, urlExpenses, accessToken, actions, data, (res) => {
					if (res.error) {
						notify("Error", name + '" couldn´t be added: ' + res.error, "danger");
						return;
					}

					log(
						logsStoreData,
						"Add",
						"Auto Expense: " + data.name + " | " + data.amount + " | " + data.deductable
					);
				});
			};

			const frequencyText = expense.frequency.split("|")[1];
			const frequencyNumber = parseFloat(expense.frequency.split("|")[0]);

			switch (frequencyText) {
				case "month":
					const months = monthsBetween(new Date(expense.timestamp), new Date());
					const shouldBeCalledMonths = months / frequencyNumber;

					if (expense.called < shouldBeCalledMonths) {
						addExpense(
							expense.name + " | Periode: " + String(expense.called + 1),
							expense.amount,
							expense.deductable
						);
						editExpensePeriodicCalled(expense.id, expense.called + 1);
					}

					break;
				case "day":
					const days = daysBetween(new Date(expense.timestamp), new Date());
					const shouldBeCalledDays = days / frequencyNumber;

					if (expense.called < shouldBeCalledDays) {
						addExpense(
							expense.name + " | Periode: " + String(expense.called + 1),
							expense.amount,
							expense.deductable
						);
						editExpensePeriodicCalled(expense.id, expense.called + 1);
					}

					break;
				case "year":
					const years = yearsBetween(new Date(expense.timestamp), new Date());
					const shouldBeCalledYears = years / frequencyNumber;

					if (expense.called < shouldBeCalledYears) {
						addExpense(
							expense.name + " | Periode: " + String(expense.called + 1),
							expense.amount,
							expense.deductable
						);
						editExpensePeriodicCalled(expense.id, expense.called + 1);
					}

					break;
				default:
					continue;
			}
		}
	}, [expensesPeriodic, accessToken, dispatch, urlExpensesPeriodic, urlExpenses, logsUrl]);

	return silkrockBalance;
}

import {
	fetchExpensesPeriodicError,
	fetchExpensesPeriodicPending,
	fetchExpensesPeriodicSuccess,
	expensesPeriodicReloadNeeded,
} from "../actions/ExpensesPeriodicActions";

const initialState = {
	anyPending: false,
	fetchPending: false,
	postPending: false,
	deletePending: false,
	editPending: false,
	expensesPeriodic: null,
	error: null,
	reloadNeeded: false,
	apiEndpoint: "expensesPeriodics",
	loadingActions: {
		before: [fetchExpensesPeriodicPending, expensesPeriodicReloadNeeded],
		success: [fetchExpensesPeriodicSuccess],
		error: [fetchExpensesPeriodicError],
	},
};

export const expensesPeriodicReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_EXPENSES_PERIODIC_PENDING":
			return {
				...state,
				anyPending: true,
				fetchPending: true,
			};
		case "FETCH_EXPENSES_PERIODIC_SUCCESS":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				expensesPeriodic: action.payload.reverse(),
			};
		case "FETCH_EXPENSES_PERIODIC_ERROR":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				error: action.error,
			};
		case "POST_EXPENSES_PERIODIC_PENDING":
			return {
				...state,
				anyPending: true,
				postPending: true,
			};
		case "POST_EXPENSES_PERIODIC_SUCCESS":
			return {
				...state,
				anyPending: false,
				postPending: false,
				expensesPeriodic: [action.payload].concat(state.expensesPeriodic),
			};
		case "POST_EXPENSES_PERIODIC_ERROR":
			return {
				...state,
				anyPending: false,
				postPending: false,
				error: action.error,
			};
		case "EDIT_EXPENSES_PERIODIC_PENDING":
			return {
				...state,
				anyPending: true,
				editPending: true,
			};
		case "EDIT_EXPENSES_PERIODIC_SUCCESS":
			return {
				...state,
				anyPending: false,
				editPending: false,
				expensesPeriodic: state.expensesPeriodic.map((expense) => {
					if (expense.id === action.payload.id) return action.payload;

					return expense;
				}),
			};
		case "EDIT_EXPENSES_PERIODIC_ERROR":
			return {
				...state,
				anyPending: false,
				editPending: false,
				error: action.error,
			};
		case "DELETE_EXPENSES_PERIODIC_PENDING":
			return {
				...state,
				anyPending: true,
				deletePending: true,
			};
		case "DELETE_EXPENSES_PERIODIC_SUCCESS":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				expensesPeriodic: state.expensesPeriodic.filter((expense) => expense.id !== action.id),
			};
		case "DELETE_EXPENSES_PERIODIC_ERROR":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				error: action.error,
			};
		case "EXPENSES_PERIODIC_RELOAD_NEEDED":
			return {
				...state,
				// reloadNeeded: action.reloadNeeded,
			};
		default:
			return state;
	}
};

import React, { useState, useMemo } from "react";
import LogTableRow from "./LogTableRow";
import TableWrapper from "./TableWrapper";
import { useDispatch, useSelector } from "react-redux";
import patchToAPI from "../scripts/patchToAPI";
import notify from "../scripts/notiAndLog";
import { log } from "../scripts/createLogEntry";
import { editSettingsError, editSettingsPending, editSettingsSuccess } from "../actions/SettingsActions";

function Settings() {
	const settings = useSelector((state) => state?.settings?.settings);
	const ceoPercentage = useSelector((state) => state.settings?.settings?.ceoFactor);
	const coinsPercentage = useSelector((state) => state.settings?.settings?.coinsFactor);
	const kassePercentage = useSelector((state) => state.settings?.settings?.kasseFactor);
	const settingsErrors = useSelector((state) => state?.settings?.error);

	const [ceoPercentageInput, setCeoPercentageInput] = useState("");
	const [coinsPercentageInput, setCoinsPercentageInput] = useState("");
	const [kassePercentageInput, setKassePercentageInput] = useState("");
	const [errors, setErrors] = useState({ percentages: "" });

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const settingsAPIEndpoint = useSelector((state) => state.settings.apiEndpoint);

	const url = apiUrl + settingsAPIEndpoint;

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const actions = {
		before: [editSettingsPending],
		success: [editSettingsSuccess],
		error: [editSettingsError],
	};

	useMemo(() => {
		if (!ceoPercentage || !coinsPercentage || !kassePercentage) return;
		setCeoPercentageInput(ceoPercentage * 100);
		setCoinsPercentageInput(coinsPercentage * 100);
		setKassePercentageInput(kassePercentage * 100);
	}, [ceoPercentage, coinsPercentage, kassePercentage]);

	function saveSplitPercentages(event) {
		if (!validateForm(errors)) return null;

		if (
			ceoPercentage === parseFloat(ceoPercentageInput) / 100 &&
			coinsPercentage === parseFloat(coinsPercentageInput) / 100 &&
			parseFloat(kassePercentageInput) / 100 === kassePercentage
		) {
			notify("Nothing new!", "Nothing to save..", "warning");
			return null;
		}

		let data = settings;
		data.ceoFactor = String(parseFloat(ceoPercentageInput) / 100);
		data.coinsFactor = String(parseFloat(coinsPercentageInput) / 100);
		data.kasseFactor = String(parseFloat(kassePercentageInput) / 100);

		patchToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify("Error", "Settings couldn´t be edited: " + res.error, "danger");
				return;
			}

			log(
				logsStoreData,
				"Edit",
				"Settings: CEO: " +
					settings.ceoFactor +
					" ->" +
					data.ceoFactor +
					" | COINS: " +
					settings.coinsFactor +
					"->" +
					data.coinsFactor +
					" | KASSE: " +
					settings.kasseFactor +
					"->" +
					data.kasseFactor
			);

			notify("Settings Edited", "You have edited the multiplier Settings!", "success");
		});
	}

	function handleChangeSplitPercentages(event) {
		event.preventDefault();
		const { name, value } = event.target;

		switch (name) {
			case "ceoPercentage":
				setCeoPercentageInput(value);
				break;
			case "coinsPercentage":
				setCoinsPercentageInput(value);
				break;
			case "kassePercentage":
				setKassePercentageInput(value);
				break;
			default:
				break;
		}

		if (name.includes("Percentage")) {
			setErrors({
				...errors,
				percentages: !(() => {
					let acc = 0;
					acc += parseFloat(value);
					if (!name.includes("coins")) acc += parseFloat(coinsPercentageInput);
					if (!name.includes("ceo")) acc += parseFloat(ceoPercentageInput);
					if (!name.includes("kasse")) acc += parseFloat(kassePercentageInput);
					if (acc === 100.0) return true;
					return false;
				})()
					? "Alle Prozente müssen zusammen 100 ergeben"
					: "",
			});
		}
	}

	const validateForm = (errors) => {
		let valid = true;

		Object.values(errors).forEach(
			// if we have an error string set valid to false
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	};

	console.log(settingsErrors);

	return (
		<div className="settings">
			<div className="card">
				<div className="settings-section">
					<h5>Gewinnaufteilungsschlüssel</h5>
					{errors.percentages.length > 0 && <span className="form-error">{errors.percentages}</span>}
					{settingsErrors && <span className="form-error">{settingsErrors}</span>}
					<form
						onSubmit={(event) => {
							event.preventDefault();
							saveSplitPercentages(event);
						}}
					>
						<label htmlFor="ceoPercentage">CEO Prozentsatz</label>
						<input
							name="ceoPercentage"
							type="number"
							step="0.01"
							min="0"
							max="100"
							value={ceoPercentageInput}
							onChange={handleChangeSplitPercentages}
							required
						/>

						<label htmlFor="coinsPercentage">Coins Prozentsatz</label>
						<input
							name="coinsPercentage"
							type="number"
							step="0.01"
							min="0"
							max="100"
							value={coinsPercentageInput}
							onChange={handleChangeSplitPercentages}
							required
						/>

						<label htmlFor="kassePercentage">Kasse Prozentsatz</label>
						<input
							name="kassePercentage"
							type="number"
							step="0.01"
							min="0"
							max="100"
							value={kassePercentageInput}
							onChange={handleChangeSplitPercentages}
							required
						/>

						<button className="btn btn-primary" type="submit" disabled={!validateForm(errors)}>
							Speichern
						</button>
					</form>
				</div>
				{/* <div className="settings-section">
					<h5>Miete</h5>
					{rentArray.map((rent) => {
						const rentAmount = rent?.totalRent || 0;
						const rentStartDate = rent?.startDate || "2020-01-01";
						const rentEndDate = rent?.endDate || "2020-01-01";
						const rentActivated = rent?.activated || false;
						const rentSenders = rent?.senders || "";
						const rentReceivers = rent?.receivers || "";

						const rentStartDateParsed = dateToInputDate(parseInt(rentStartDate));
						const rentEndDateParsed = dateToInputDate(parseInt(rentEndDate));

						return (
							<form
								onSubmit={(event) => {
									event.preventDefault();
									saveRentSettings(event);
								}}
								key={rentStartDateParsed}
							>
								<label htmlFor="rentActivated">Aktiv?</label>
								<input name="rentActivated" type="checkbox" defaultChecked={rentActivated} />

								<label htmlFor="rentAmount">Mietbetrag</label>
								<input name="rentAmount" type="number" step="0.01" value={rentAmount} required />

								<label htmlFor="rentStartDate">Start der Miete</label>
								<input name="rentStartDate" type="date" value={rentStartDateParsed} required />
								<ReactDatePicker
									name="rentStartDate"
									selected={startDate}
									dateFormat="dd.MM.yyyy"
									onChange={(date) => setStartDate(date)}
									required
								/>

								<label htmlFor="rentEndDate">Ende der Miete</label>
								<input
									name="rentEndDate"
									type="date"
									disabled={rentActivated}
									value={rentActivated ? dateToInputDate(new Date()) : rentEndDateParsed}
								/>

								<label htmlFor="rentReceivers">Empfänger der Miete</label>
								<input name="rentReceivers" type="text" value={rentReceivers} />

								<label htmlFor="rentSenders">Zahlende der Miete</label>
								<input name="rentSenders" type="text" value={rentSenders} />

								<button className="btn btn-primary" type="submit">
									Speichern
								</button>
							</form>
						);
					})}
				</div> */}
			</div>
			<TableWrapper dataName={"logs"} dataRow={LogTableRow} fullscreen={false} />
		</div>
	);
}

export default Settings;

import React from "react";
import { faUser, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoutButton from "./LogoutButton";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ProfileButton(props) {
	const serverPath = useSelector((state) => state.app.serverPath);

	return (
		<div className="dropdown">
			<div className="profileButton dropbtn">
				<FontAwesomeIcon icon={faUser} />
			</div>
			<div className="dropdown-content right-0">
				<NavLink to={serverPath + "/profile"} key="Profile">
					<FontAwesomeIcon icon={faUser} /> Profile
				</NavLink>
				<NavLink to={serverPath + "/settings"} key="Settings">
					<FontAwesomeIcon icon={faSlidersH} /> Settings
				</NavLink>
				<NavLink to="#logout">
					<LogoutButton>Logout</LogoutButton>
				</NavLink>
			</div>
		</div>
	);
}

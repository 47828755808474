import React from "react";

export default function LoadingSpinner(props) {
	return (
		<div className="flexCenter">
			<div className="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

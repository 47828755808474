import { useSelector } from "react-redux";
import { useMemo } from "react";

export default function useUserTransactions(username) {
	const loggedIn = useSelector((state) => state.user.loggedIn);

	// load data, for calulating user balance
	const projects = useSelector((state) => state.projects.projects);
	const payments = useSelector((state) => state.payments.payments);
	const settings = useSelector((state) => state.settings.settings);

	const storagePrice = settings ? settings.storagePrice : null;
	const rentArray = settings?.rent || [];
	const amountOfUsers = settings?.ceos?.length || 4;

	const userTransactions = useMemo(() => {
		if (!projects || !payments || !storagePrice || !loggedIn) return [];

		const userProjectProfits = projects.map((project) => ({
			id: project.id,
			timestamp: project.timestamp,
			description: project.name,
			amount:
				((parseFloat(project.budget) -
					parseFloat(project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)) -
					parseFloat(project.storage) * parseFloat(storagePrice)) *
					parseFloat(project.splitPercentage.ceo)) /
					amountOfUsers +
				(parseFloat(project.budget) -
					parseFloat(project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0)) -
					parseFloat(project.storage) * parseFloat(storagePrice)) *
					((parseFloat(project.splitPercentage.coins) *
						(
							project.coins.find((coin) => coin.name.toLowerCase() === username.toLowerCase()) || {
								amount: 0,
							}
						).amount) /
						100),
		}));

		const userPaymentsTx = payments
			.map((payment) =>
				payment.to.toLowerCase() === username.toLowerCase()
					? {
							id: payment.id,
							timestamp: payment.timestamp,
							description: payment.description,
							amount: -1 * parseFloat(payment.amount),
					  }
					: null
			)
			.filter((el) => el != null);

		let userRentTx = [];

		for (let rent of rentArray) {
			if (
				rent.senders.map((sender) => sender.toLowerCase()).includes(username.toLowerCase()) ||
				rent.receivers.map((receiver) => receiver.toLowerCase()).includes(username.toLowerCase())
			) {
				const amountOfUsers = rent.senders.length + rent.receivers.length;
				const monthsBetween = (d1, d2) => {
					var months;
					months = (d2.getFullYear() - d1.getFullYear()) * 12;
					months -= d1.getMonth();
					months += d2.getMonth();
					return months <= 0 ? 0 : months;
				};
				const endDate = rent?.activated ? new Date() : new Date(parseInt(rent.endDate));
				const rentPeriod = monthsBetween(new Date(parseInt(rent.startDate)), endDate);

				const rentAmount = rent.senders.find((name) => name.toLowerCase() === username.toLowerCase())
					? -1 * (rent.totalRent / amountOfUsers)
					: rent.totalRent / amountOfUsers;

				for (let i = 0; i <= rentPeriod; i++) {
					const date = new Date(
						new Date(parseInt(rent.startDate)).setMonth(new Date(parseInt(rent.startDate)).getMonth() + i)
					);
					userRentTx.push({
						id: date,
						timestamp: new Date(date.getFullYear(), date.getMonth(), 1).getTime(),
						description: "Automatische Miete",
						amount: rentAmount,
					});
				}
			}
		}

		const userTxs = userPaymentsTx.concat(userProjectProfits).concat(userRentTx);

		return userTxs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
	}, [projects, payments, loggedIn, storagePrice, username, amountOfUsers, rentArray]);

	return userTransactions;
}

import { combineReducers } from "redux";
import { projectsReducer } from "./projectReducers";
import { userReducers } from "./userReducers";
import { appLogicReducer } from "./appLogicReducer";
import { settingsReducer } from "./settingsReducers";
import { paymentsReducer } from "./paymentReducers";
import { expensesReducer } from "./expenseReducers";
import { logsReducer } from "./logReducers";
import { expensesPeriodicReducer } from "./expensePeriodicReducers";

const allReducer = combineReducers({
	app: appLogicReducer,
	projects: projectsReducer,
	expenses: expensesReducer,
	payments: paymentsReducer,
	settings: settingsReducer,
	user: userReducers,
	logs: logsReducer,
	expensesPeriodic: expensesPeriodicReducer,
});

export default allReducer;

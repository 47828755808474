import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { postProjectsError, postProjectsPending, postProjectsSuccess } from "../actions/ProjectsActions";
import { log } from "../scripts/createLogEntry";
import notify from "../scripts/notiAndLog";
import postToAPI from "../scripts/postToAPI";

export function ProjectsInputCard(props) {
	const maxStep = 3;
	const [currentStep, setCurrentStep] = useState(1);
	const [stepClassName, setStepClassName] = useState(["step active ", "step ", "step "]);

	const [projectName, setProjectName] = useState("");
	const [budget, setBudget] = useState("");
	const [expenses, setExpenses] = useState([{ name: "", amount: "", deductable: false }]);
	const [coins, setCoins] = useState(
		props.ceos.reduce((obj, ceo) => {
			return { ...obj, [ceo.name]: "" };
		}, {})
	);
	const [errors, setErrors] = useState({ projectName: "", budget: "", expenses: "", coins: "" });

	const dispatch = useDispatch();

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const username = useSelector((state) => state.user.username);
	const accessToken = useSelector((state) => state.user.accessToken);

	const logsApiEndpoint = useSelector((state) => state.logs.apiEndpoint);
	const logsUrl = apiUrl + logsApiEndpoint;

	const logsStoreData = {
		dispatch: dispatch,
		accessToken: accessToken,
		url: logsUrl,
		username: username,
	};

	const projectsApiEndpoint = useSelector((state) => state.projects.apiEndpoint);

	const url = apiUrl + projectsApiEndpoint;

	const projectsAnyPending = useSelector((state) => state.projects.anyPending);

	const settings = useSelector((state) => state.settings.settings);

	if (!settings) return null;

	const ceoFactor = settings.ceoFactor;
	const kasseFactor = settings.kasseFactor;
	const coinsFactor = settings.coinsFactor;

	const actions = {
		before: [postProjectsPending],
		success: [postProjectsSuccess],
		error: [postProjectsError],
	};

	const addProject = (name, budget, expenses, coins) => {
		if (!validateForm(errors)) return;
		const timestamp = Date.now();

		let coinsArr = [];

		for (let coinName of Object.keys(coins)) {
			coinsArr.push({ name: coinName, amount: coins[coinName] || "0" });
		}

		if (budget.trim() === "") budget = "0.00";

		for (let expense of expenses) {
			if (expense.name.trim() === "") expense.name = "No Name";
			if (expense.amount.trim() === "") expense.amount = "0.00";
		}

		const data = {
			timestamp: timestamp,
			name: name.trim(),
			budget: budget,
			expenses: expenses,
			storage: "0.00",
			coins: coinsArr,
			splitPercentage: {
				ceo: ceoFactor,
				kasse: kasseFactor,
				coins: coinsFactor,
			},
		};

		postToAPI(dispatch, url, accessToken, actions, data, (res) => {
			if (res.error) {
				notify(res.error?.name, '"' + name + '" couldn´t be added: ' + res.error?.message, "danger");
				return;
			}

			log(
				logsStoreData,
				"Add",
				"Project: " +
					data.name +
					" | " +
					data.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0) +
					" | " +
					data.budget +
					" | Coins: " +
					data.coins.reduce((txt, coin) => txt + "; " + coin.name + ": " + coin.amount, "")
			);

			notify("Project Added", 'You have added "' + name + '" to the Projects!', "success");

			setCurrentStep(1);
			setStepClassName(["step active ", "step ", "step "]);
			setProjectName("");
			setBudget("");
			setExpenses([{ name: "", amount: "", deductable: false }]);
			setCoins(
				props.ceos.reduce((obj, ceo) => {
					return { ...obj, [ceo.name]: "" };
				}, {})
			);
		});
	};

	function handleChange(event) {
		if (event.preventDefault) event.preventDefault();
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case "projectName":
				value.trim() === ""
					? setErrors({ ...errors, projectName: "Bitte Projekt Namen angeben." })
					: setErrors({ ...errors, projectName: "" });

				setProjectName(value);
				break;
			case "budget":
				value.trim() === ""
					? setErrors({ ...errors, budget: "Bitte Budget angeben." })
					: setErrors({ ...errors, budget: "" });

				setBudget(value.replace(",", "."));
				break;
			default:
				break;
		}

		// change particular expense object which is an element in an array of expense objects
		if (name.includes("expense")) {
			const index = name.split("-")[1];
			let newExpenses = [...expenses];

			if (name.includes("Amount")) {
				newExpenses[index] = { ...newExpenses[index], amount: value };
			}
			if (name.includes("Name")) {
				newExpenses[index] = { ...newExpenses[index], name: value };
			}
			if (name.includes("Deductable")) {
				newExpenses[index] = { ...newExpenses[index], deductable: !newExpenses[index].deductable };
			}

			setExpenses([...newExpenses]);
		}

		// Check if coins sum up to 100
		if (name.includes("coins-")) {
			const currCeo = name.split("-")[1];
			setCoins({ ...coins, [currCeo]: value.replace(",", ".") });
			setErrors({
				...errors,
				coins: ((coins) => {
					let acc = 0;
					for (let ceo in coins) {
						if (currCeo.toLowerCase() === ceo.toLowerCase()) {
							acc += value.length > 0 ? parseFloat(value) : 0;
						} else {
							acc += coins[ceo] ? parseFloat(coins[ceo]) : 0;
						}
					}
					if (acc === 100.0) return true;
					return false;
				})(coins)
					? ""
					: "Alle Coins müssen zusammen 100 ergeben",
			});
		}
	}

	const validateForm = (errors) => {
		let valid = true;

		Object.values(errors).forEach(
			// if we have an error string set valid to false
			(val) => val.length > 0 && (valid = false)
		);
		if (projectName.trim() === "") {
			setErrors({ ...errors, projectName: "Bitte Projekt Namen angeben." });
			valid = false;
		}

		if (budget.trim() === "") {
			setErrors({ ...errors, budget: "Bitte Budget angeben." });
			valid = false;
		}

		return valid;
	};

	const ceoCoinInputs = props.ceos.map((ceo) => (
		<div className="inputs-coins" key={ceo.name}>
			{ceo.name[0].toUpperCase() + ceo.name.substring(1, ceo.name.length)}:{" "}
			<input
				key={ceo.name}
				name={"coins-" + ceo.name}
				className={"input-coins " + (errors.coins.length > 0 ? "input-error" : "")}
				type="number"
				step="0.01"
				min="0"
				max="100"
				value={coins[ceo.name]}
				placeholder="25"
				onChange={handleChange}
				required
			/>
		</div>
	));

	let stepIndicators = [];
	for (let i = 0; i < maxStep; i++) {
		stepIndicators.push(<div key={i} className={stepClassName[i]}></div>);
	}

	// The "next" and "previous" button functions

	function _next() {
		const nextStep = currentStep + 1;

		let NewStepClassName = [...stepClassName];

		if (!validateForm(errors)) {
			NewStepClassName[currentStep - 1] = NewStepClassName[currentStep - 1].replace("finish ", "");
			if (!NewStepClassName[currentStep - 1].includes("invalid")) NewStepClassName[currentStep - 1] += "invalid ";

			setStepClassName(NewStepClassName);
			return;
		} else {
			NewStepClassName[currentStep - 1] = NewStepClassName[currentStep - 1].replace("invalid ", "");
			if (!NewStepClassName[currentStep - 1].includes("finish")) NewStepClassName[currentStep - 1] += "finish ";
		}

		NewStepClassName[currentStep - 1] = NewStepClassName[currentStep - 1].replace("active ", "");
		NewStepClassName[nextStep - 1] += "active ";

		setCurrentStep(nextStep);
		setStepClassName(NewStepClassName);
	}

	function _prev() {
		const prevStep = currentStep - 1;

		let NewStepClassName = [...stepClassName];

		if (!validateForm(errors)) {
			NewStepClassName[currentStep - 1] = NewStepClassName[currentStep - 1].replace("finish ", "");
			if (!NewStepClassName[currentStep - 1].includes("invalid")) NewStepClassName[currentStep - 1] += "invalid ";

			setStepClassName(NewStepClassName);
			return;
		} else {
			NewStepClassName[currentStep - 1] = NewStepClassName[currentStep - 1].replace("invalid ", "");
			if (!NewStepClassName[currentStep - 1].includes("finish")) NewStepClassName[currentStep - 1] += "finish ";
		}

		NewStepClassName[currentStep - 1] = NewStepClassName[currentStep - 1].replace("active ", "");
		NewStepClassName[prevStep - 1] += "active ";

		setCurrentStep(prevStep);
		setStepClassName(NewStepClassName);
	}

	return (
		<>
			<h5>Neues Projekt</h5>

			<div id="stepIndication" className="text-center">
				{stepIndicators}
			</div>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					addProject(projectName.trim(), budget, expenses, coins);
				}}
			>
				<NameAndBudgetStep
					currentStep={currentStep}
					handleChange={handleChange}
					errors={errors}
					projectName={projectName}
					budget={budget}
				/>

				<ExpensesStep
					currentStep={currentStep}
					handleChange={handleChange}
					expenses={expenses}
					setExpenses={setExpenses}
				/>

				<CoinsStep
					currentStep={currentStep}
					handleChange={handleChange}
					errors={errors}
					coins={coins}
					ceoCoinInputs={ceoCoinInputs}
				/>
				<hr />
				<div>
					<PreviousButton currentStep={currentStep} prev={_prev} />
					<NextButton currentStep={currentStep} next={_next} maxStep={maxStep} />
					{currentStep === maxStep && (
						<button
							className="btn btn-primary float-right"
							type="submit"
							disabled={projectsAnyPending || !validateForm(errors)}
						>
							Projekt anlegen
						</button>
					)}
				</div>
			</form>
		</>
	);
}

function NameAndBudgetStep(props) {
	if (props.currentStep !== 1) {
		return null;
	}

	return (
		<>
			<label htmlFor="projectName">Projekt Name</label>
			{props.errors.projectName.length > 0 && <span className="form-error">{props.errors.projectName}</span>}
			<input
				name="projectName"
				placeholder="Apple Werbung"
				value={props.projectName}
				onChange={props.handleChange}
				required
				autoFocus
			></input>

			<label htmlFor="budget">Budget</label>
			{props.errors.budget.length > 0 && <span className="form-error">{props.errors.budget}</span>}
			<input
				name="budget"
				placeholder="1 Melon"
				type="number"
				step="0.01"
				value={props.budget}
				onChange={props.handleChange}
				required
			></input>
		</>
	);
}

function ExpensesStep(props) {
	const { handleChange, setExpenses, expenses } = props;

	function removeExpense(expenses, index) {
		const newExpenses = [...expenses];
		newExpenses.splice(index, 1);
		return newExpenses;
	}

	const expensesInputs = useMemo(() => {
		return expenses.map((expense, i) => {
			return (
				<>
					<div>
						<label htmlFor="expenseName">Ausgaben Beschreibung</label>
						{i > 0 && (
							<button
								className="btn btn-delete float-right"
								onClick={() => setExpenses([...removeExpense(expenses, i)])}
							>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						)}
					</div>
					<input
						name={"expenseName-" + i}
						// className={errors.expenseName.length > 0 ? "input-error" : ""}
						placeholder="Automiete"
						value={expense.name}
						onChange={handleChange}
						required
						autoFocus
					></input>
					<label htmlFor="amount">Ausgaben Summe</label>
					<input
						name={"expenseAmount-" + i}
						// className={errors.amount.length > 0 ? "input-error" : ""}
						placeholder="13,24"
						type="number"
						step="0.01"
						value={expense.amount}
						onChange={handleChange}
						required
					></input>
					<label htmlFor="deductable"> Beleg vorhanden / Absetzbar?</label>
					<Switch
						name="deductable"
						checked={expense.deductable}
						onChange={(value) => handleChange({ target: { name: "expenseDeductable-" + i, value } })}
						onColor="#86d3ff"
						onHandleColor="#2693e6"
						handleDiameter={20}
						uncheckedIcon={false}
						checkedIcon={false}
						boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
						activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
						height={15}
						width={35}
					/>
					<hr />
				</>
			);
		});
	}, [expenses, handleChange, setExpenses]);

	if (props.currentStep !== 2) {
		return null;
	}

	const addExpenseInputsButton = (
		<div className="text-center">
			<button
				className="btn btn-circle"
				onClick={() => setExpenses([...expenses, { name: "", amount: "", deductable: false }])}
			>
				+
			</button>
		</div>
	);

	return (
		<>
			{expensesInputs}
			{addExpenseInputsButton}
		</>
	);
}

function CoinsStep(props) {
	if (props.currentStep !== 3) {
		return null;
	}

	return (
		<>
			<label>Coins</label>
			{props.errors.coins.length > 0 && <span className="form-error">{props.errors.coins}</span>}
			<div value={props.coins}>{props.ceoCoinInputs}</div>
		</>
	);
}

function PreviousButton(props) {
	let currentStep = props.currentStep;
	// If the current step is not 1, then render the "previous" button
	if (currentStep !== 1) {
		return (
			<button className="btn btn-secondary" type="button" onClick={props.prev}>
				Zurück
			</button>
		);
	}
	// ...else return nothing
	return null;
}

function NextButton(props) {
	let currentStep = props.currentStep;
	// If the current step is not 3, then render the "next" button
	if (currentStep < props.maxStep) {
		return (
			<button className="btn btn-primary float-right" type="button" onClick={props.next}>
				Weiter
			</button>
		);
	}
	// ...else render nothing
	return null;
}

import {
	fetchPaymentsError,
	fetchPaymentsPending,
	fetchPaymentsSuccess,
	paymentsReloadNeeded,
} from "../actions/PaymentsActions";

const initialState = {
	anyPending: false,
	fetchPending: false,
	deletePending: false,
	editPending: false,
	postPending: false,
	payments: [],
	error: null,
	reloadNeeded: false,
	apiEndpoint: "payments",
	loadingActions: {
		before: [fetchPaymentsPending, paymentsReloadNeeded],
		success: [fetchPaymentsSuccess],
		error: [fetchPaymentsError],
	},
};

export const paymentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_PAYMENTS_PENDING":
			return {
				...state,
				anyPending: true,
				fetchPending: true,
			};
		case "FETCH_PAYMENTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				payments: action.payload.reverse(),
			};
		case "FETCH_PAYMENTS_ERROR":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				error: action.error,
			};
		case "POST_PAYMENTS_PENDING":
			return {
				...state,
				anyPending: true,
				postPending: true,
			};
		case "POST_PAYMENTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				postPending: false,
				payments: [action.payload].concat(state.payments),
			};
		case "POST_PAYMENTS_ERROR":
			return {
				...state,
				anyPending: false,
				postPending: false,
				error: action.error,
			};
		case "EDIT_PAYMENTS_PENDING":
			return {
				...state,
				anyPending: true,
				editPending: true,
			};
		case "EDIT_PAYMENTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				editPending: false,
				payments: state.payments.map((payment) => {
					if (payment.id === action.payload.id) return action.payload;

					return payment;
				}),
			};
		case "EDIT_PAYMENTS_ERROR":
			return {
				...state,
				anyPending: false,
				editPending: false,
				error: action.error,
			};
		case "DELETE_PAYMENTS_PENDING":
			return {
				...state,
				anyPending: true,
				deletePending: true,
			};
		case "DELETE_PAYMENTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				payments: state.payments.filter((payment) => payment.id !== action.id),
			};
		case "DELETE_PAYMENTS_ERROR":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				error: action.error,
			};
		case "PAYMENTS_RELOAD_NEEDED":
			return {
				...state,
				// reloadNeeded: action.reloadNeeded,
			};
		default:
			return state;
	}
};

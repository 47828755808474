import React from "react";

export default function SilkrockLogo(props) {
	const colors = [
		"#699cc6",
		"#acb6b6",
		"#ffad4a",
		"#ffe000",
		"#fe87a3",
		"#f4da40",
		"#ef3340",
		"#685a5c",
		"#ff665e",
		"#3a557c",
		"#53caec",
		"#56944f",
	];
	const d = new Date();
	const day = d.getDate();

	const colorIndex = day % 12;

	return (
		<svg id="Ebene_13" data-name="Ebene 13" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1232.56 285.62">
			<defs>
				<style>
					{`.cls-1 {
                                isolation: isolate;
                            }

                            .cls-2 {
                                fill: ` +
						colors[colorIndex] +
						`;
                            }`}
				</style>
			</defs>
			<title>silkrock video production berlin logo</title>
			<g className="cls-1">
				<path
					className="cls-2"
					d="M57.87,276.94Q58.69,298,68.41,306.7t24.18,8.68a82.7,82.7,0,0,0,12.81-1,43.16,43.16,0,0,0,12.19-3.72,28.1,28.1,0,0,0,9.3-7,16.23,16.23,0,0,0,3.72-11,14.29,14.29,0,0,0-3.51-9.51,38,38,0,0,0-9.3-7.65,72.28,72.28,0,0,0-13.43-6.2l-15.91-5.58q-12.82-4.54-25.63-9.51A103.42,103.42,0,0,1,39.89,242a60.64,60.64,0,0,1-16.53-17.57q-6.41-10.33-6.41-25.21,0-14.46,6.2-25.21a53,53,0,0,1,16.74-17.77A78.62,78.62,0,0,1,64.07,145.7a114.1,114.1,0,0,1,28.52-3.51,118.1,118.1,0,0,1,31,3.93,69.6,69.6,0,0,1,25,12.19,61.12,61.12,0,0,1,16.95,21.08q6.4,12.82,7.23,30.17h-3.51c-1.8,0-3.66-.06-5.58-0.21s-3.72-.21-5.37-0.21H125.66q-2.07-15.7-11.37-21.08a42.65,42.65,0,0,0-21.7-5.37q-13.64,0-20.87,4.55t-7.23,12.4q0,9.51,12.19,15.71a245.92,245.92,0,0,0,28.31,12l2.07,0.83q12.4,4.55,25.21,9.51a109.54,109.54,0,0,1,22.94,12,61.8,61.8,0,0,1,16.53,16.74q6.4,9.72,6.41,23.35,0,17.36-6.82,29.76a57,57,0,0,1-18.6,20.25,84.06,84.06,0,0,1-27.28,11.57,140.41,140.41,0,0,1-32.86,3.72,102.75,102.75,0,0,1-31.41-4.75,72.9,72.9,0,0,1-26-14.47,72,72,0,0,1-18-24.39q-6.82-14.67-7.23-34.51H57.87Z"
					transform="translate(-9.92 -69.86)"
				/>
			</g>
			<g className="cls-1">
				<path
					className="cls-2"
					d="M242.22,69.86V117.8H195.1V69.86h47.12Zm0,76.05V351.34H195.1V145.91h47.12Z"
					transform="translate(-9.92 -69.86)"
				/>
				<path
					className="cls-2"
					d="M313.31,69.86V351.34H266.19V69.86h47.12Z"
					transform="translate(-9.92 -69.86)"
				/>
			</g>
			<g className="cls-1">
				<path
					className="cls-2"
					d="M439.79,224.86q8.68,14.47,18.39,30.79t19.43,32.86q9.71,16.54,19.22,32.65T515,351.34H460.87l-54.15-93-23.15,23.15v69.85H336.45V69.86h47.12V220.72L422,183.11l38-37.2h58.28Z"
					transform="translate(-9.92 -69.86)"
				/>
			</g>
			<g className="cls-1">
				<path
					className="cls-2"
					d="M539.81,145.91h47.12v9.51a71,71,0,0,1,17.57-9.71,58.79,58.79,0,0,1,20.87-3.51,51.53,51.53,0,0,1,10.13,1.24,72.47,72.47,0,0,1,12.19,3.72v45.47q-9.51-3.3-15.71-5.17a43.25,43.25,0,0,0-7.44-1.86,35.62,35.62,0,0,0-16.12,3.51,34.55,34.55,0,0,0-11.78,9.51,42.8,42.8,0,0,0-7.23,13.85A54.59,54.59,0,0,0,586.93,229V351.34H539.81V145.91Z"
					transform="translate(-9.92 -69.86)"
				/>
				<path
					className="cls-2"
					d="M648.52,248.83a135.21,135.21,0,0,1,6.2-41.33,98.37,98.37,0,0,1,18.39-33.89,88.37,88.37,0,0,1,30-22.94q17.77-8.47,40.92-8.47t41.13,8.47A87.93,87.93,0,0,1,815.3,173.6a101.32,101.32,0,0,1,18.6,33.89,136.55,136.55,0,0,1,0,82.67,98.9,98.9,0,0,1-18.6,33.69,86.8,86.8,0,0,1-30.17,22.53q-18,8.26-41.13,8.27t-40.92-8.27a87.21,87.21,0,0,1-30-22.53,96.07,96.07,0,0,1-18.39-33.69A135.14,135.14,0,0,1,648.52,248.83Zm46.71,0a95.82,95.82,0,0,0,3.31,25.63,64.59,64.59,0,0,0,9.71,20.87,48.45,48.45,0,0,0,15.5,14.05,42.28,42.28,0,0,0,40.51,0,48.57,48.57,0,0,0,15.5-14.05,68.15,68.15,0,0,0,9.92-20.87,95.24,95.24,0,0,0,0-51.25,71.07,71.07,0,0,0-9.92-21.08,47.85,47.85,0,0,0-15.5-14.26,42.28,42.28,0,0,0-40.51,0,47.74,47.74,0,0,0-15.5,14.26,67.25,67.25,0,0,0-9.71,21.08A95.86,95.86,0,0,0,695.22,248.83Z"
					transform="translate(-9.92 -69.86)"
				/>
				<path
					className="cls-2"
					d="M990.76,219.48Q985,199.24,975.46,191t-24-8.27a37.27,37.27,0,0,0-19.43,5.17,44.76,44.76,0,0,0-14.47,13.85,68.09,68.09,0,0,0-8.89,20,90,90,0,0,0-3.1,23.77,107.76,107.76,0,0,0,3.1,25.83,81,81,0,0,0,8.89,22.32,51.65,51.65,0,0,0,14.05,15.71,31.52,31.52,0,0,0,19,6q15.7,0,25.42-13.64t13.43-32.65h50q-5,40.51-28.52,63.45t-61.59,22.94q-23.56,0-40.71-9.71a86.48,86.48,0,0,1-28.31-25.42A110.93,110.93,0,0,1,863.86,285a148.83,148.83,0,0,1-5.37-39.47A122.4,122.4,0,0,1,865.1,205a97.58,97.58,0,0,1,18.81-32.86,88.51,88.51,0,0,1,29.55-21.91,90.18,90.18,0,0,1,38.44-8.06q18.6,0,33.48,5.79A76.67,76.67,0,0,1,1011,164.1a90.79,90.79,0,0,1,18,24.59,119.9,119.9,0,0,1,10.54,30.79H990.76Z"
					transform="translate(-9.92 -69.86)"
				/>
				<path
					className="cls-2"
					d="M1163.94,224.86q8.68,14.47,18.39,30.79t19.43,32.86q9.71,16.54,19.22,32.65t18.19,30.17H1185l-54.15-93-23.15,23.15v69.85h-47.12V69.86h47.12V220.72l38.44-37.61,38-37.2h58.28Z"
					transform="translate(-9.92 -69.86)"
				/>
			</g>
		</svg>
	);
}

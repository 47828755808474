import {
	userLoginError,
	userLoginPending,
	userLoginSuccess,
	// setLoginCookie,
	setUsername,
	userLogoutPending,
	userLogoutSuccess,
} from "../actions/UserActions";
import Cookies from "universal-cookie";
import getFromAPI from "./getFromAPI";
import url from "url";
import postToAPI from "./postToAPI";

const useUserLogin = (dispatch, username, pass, apiUrl) => {
	const loginData = {
		email: username,
		password: pass,
	};
	const loginUrl = apiUrl + "Users/login";

	const actions = {
		before: [userLoginPending],
		success: [userLoginSuccess],
		error: [userLoginError],
	};

	postToAPI(dispatch, loginUrl, null, actions, loginData, (res) => {
		const cookies = new Cookies();
		cookies.set("accessToken", res.id);
		cookies.set("ttl", res.ttl);
		cookies.set("sessionCreated", res.created);
		cookies.set("userId", res.userId);

		getUsername(dispatch, apiUrl, res.id, res.userId);
	});
};

export const UserLogout = (dispatch, apiUrl, accessToken) => {
	let logoutUrl = url.resolve(apiUrl, "Users/logout");

	const actions = {
		before: [userLogoutPending],
		success: [userLogoutSuccess],
		error: [userLogoutSuccess],
	};

	const cookies = new Cookies();
	cookies.set("accessToken", null);
	cookies.set("ttl", null);
	cookies.set("sessionCreated", null);
	cookies.set("userId", null);

	getFromAPI(dispatch, logoutUrl, accessToken, actions, (res) => {});
};

export const getUsername = (dispatch, apiUrl, accessToken, userId) => {
	let userIdURL = url.resolve(apiUrl, "Users/" + userId);

	const actions = {
		success: [setUsername],
		error: [userLoginError],
	};

	getFromAPI(dispatch, userIdURL, accessToken, actions, (res) => null);
};

export default useUserLogin;

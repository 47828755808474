export const fetchExpensesPending = () => {
	return {
		type: "FETCH_EXPENSES_PENDING",
	};
};

export const fetchExpensesSuccess = (expenses) => {
	return {
		type: "FETCH_EXPENSES_SUCCESS",
		payload: expenses,
	};
};

export const fetchExpensesError = (error) => {
	return {
		type: "FETCH_EXPENSES_ERROR",
		error: error,
	};
};

export const postExpensesPending = () => {
	return {
		type: "POST_EXPENSES_PENDING",
	};
};

export const postExpensesSuccess = (expense) => {
	return {
		type: "POST_EXPENSES_SUCCESS",
		payload: expense,
	};
};

export const postExpensesError = (error) => {
	return {
		type: "POST_EXPENSES_ERROR",
		error: String(error),
	};
};

export const editExpensesPending = () => {
	return {
		type: "EDIT_EXPENSES_PENDING",
	};
};

export const editExpensesSuccess = (expense) => {
	return {
		type: "EDIT_EXPENSES_SUCCESS",
		payload: expense,
	};
};

export const editExpensesError = (error) => {
	return {
		type: "EDIT_EXPENSES_ERROR",
		error: String(error),
	};
};

export const deleteExpensesPending = () => {
	return {
		type: "DELETE_EXPENSES_PENDING",
	};
};

export const deleteExpensesSuccess = (res, data) => {
	return {
		type: "DELETE_EXPENSES_SUCCESS",
		id: data.id,
	};
};

export const deleteExpensesError = (error) => {
	return {
		type: "DELETE_EXPENSES_ERROR",
		error: String(error),
	};
};

export const expensesReloadNeeded = () => {
	return {
		type: "EXPENSES_RELOAD_NEEDED",
	};
};

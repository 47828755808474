import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function Analytics(props) {
	return (
		<div className="flex-row analytics">
			<SalesProfitCard></SalesProfitCard>
			<UserIncomeCard></UserIncomeCard>
			<YTDCard></YTDCard>
		</div>
	);
}

export function SalesProfitCard(props) {
	const projects = useSelector((state) => state.projects.projects);
	const expenses = useSelector((state) => state.expenses.expenses);
	const loggedIn = useSelector((state) => state.user.loggedIn);
	const serverPath = useSelector((state) => state.app.serverPath);

	if (!projects || !loggedIn || !expenses) return <div></div>;

	const monthLabels = [
		"Januar",
		"Februar",
		"März",
		"April",
		"Mai",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember",
	];

	let totalSales = [];
	let monthLabelsAdjusted = [];

	for (let i = 0; i < 12; i++) {
		let dt = new Date();
		let month = dt.getMonth() - i;
		let year = dt.getFullYear();
		let monthFirst = Date.parse(new Date(year, month, 1));
		let monthLast = Date.parse(new Date(year, month + 1, 0));

		totalSales.push(
			projects.reduce((total, project) => {
				const projDate = Date.parse(new Date(project.timestamp));
				let sales = monthLast > projDate && projDate > monthFirst ? parseFloat(project.budget) : 0;
				return total + sales;
			}, 0)
		);

		monthLabelsAdjusted.push(monthLabels[new Date(monthFirst).getMonth()]);
	}

	monthLabelsAdjusted.reverse();
	totalSales.reverse();

	let totalProfit = [];

	for (let i = 0; i < 12; i++) {
		let dt = new Date();
		let month = dt.getMonth() - i;
		let year = dt.getFullYear();
		let monthFirst = Date.parse(new Date(year, month, 1));
		let monthLast = Date.parse(new Date(year, month + 1, 0));

		totalProfit.push(
			projects.reduce((total, project) => {
				const projDate = Date.parse(new Date(project.timestamp));
				let profit =
					monthLast > projDate && projDate > monthFirst
						? parseFloat(project.budget) -
						  parseFloat(project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0))
						: 0;
				return total + profit;
			}, 0) -
				expenses.reduce((total, expense) => {
					const projDate = Date.parse(new Date(expense.timestamp));
					let amount = monthLast > projDate && projDate > monthFirst ? parseFloat(expense.amount) : 0;
					return total + amount;
				}, 0)
		);
	}

	totalProfit.reverse();

	let totalProjectProfit = [];

	for (let i = 0; i < 12; i++) {
		let dt = new Date();
		let month = dt.getMonth() - i;
		let year = dt.getFullYear();
		let monthFirst = Date.parse(new Date(year, month, 1));
		let monthLast = Date.parse(new Date(year, month + 1, 0));

		totalProjectProfit.push(
			projects.reduce((total, project) => {
				const projDate = Date.parse(new Date(project.timestamp));
				let profit =
					monthLast > projDate && projDate > monthFirst
						? parseFloat(project.budget) -
						  parseFloat(project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0))
						: 0;
				return total + profit;
			}, 0)
		);
	}

	totalProjectProfit.reverse();

	const data = {
		labels: monthLabelsAdjusted,
		datasets: [
			{
				label: "Umsätze",
				data: totalSales.map((sale) => parseFloat(sale).toFixed(2)),
				backgroundColor: [
					"rgba(54, 162, 235, 0.2)",
					"rgba(255, 99, 132, 0.2)",
					"rgba(255, 206, 86, 0.2)",
					"rgba(75, 192, 192, 0.2)",
					"rgba(153, 102, 255, 0.2)",
					"rgba(255, 159, 64, 0.2)",
				],
				borderColor: [
					"rgba(54, 162, 235, 1)",
					"rgba(255, 99, 132, 1)",
					"rgba(255, 206, 86, 1)",
					"rgba(75, 192, 192, 1)",
					"rgba(153, 102, 255, 1)",
					"rgba(255, 159, 64, 1)",
				],
				borderWidth: 1,
			},
			{
				label: "Gewinn",
				data: totalProfit.map((profit) => parseFloat(profit).toFixed(2)),
				backgroundColor: [
					"rgba(75, 192, 192, 0.2)",
					"rgba(54, 162, 235, 0.2)",
					"rgba(255, 99, 132, 0.2)",
					"rgba(255, 206, 86, 0.2)",
					"rgba(153, 102, 255, 0.2)",
					"rgba(255, 159, 64, 0.2)",
				],
				borderColor: [
					"rgba(75, 192, 192, 1)",
					"rgba(54, 162, 235, 1)",
					"rgba(255, 99, 132, 1)",
					"rgba(255, 206, 86, 1)",
					"rgba(153, 102, 255, 1)",
					"rgba(255, 159, 64, 1)",
				],
				borderWidth: 1,
			},
			{
				label: "Projektgewinne",
				data: totalProjectProfit.map((profit) => parseFloat(profit).toFixed(2)),
				backgroundColor: [
					"rgba(255, 99, 132, 0.2)",
					"rgba(75, 192, 192, 0.2)",
					"rgba(54, 162, 235, 0.2)",
					"rgba(255, 206, 86, 0.2)",
					"rgba(153, 102, 255, 0.2)",
					"rgba(255, 159, 64, 0.2)",
				],
				borderColor: [
					"rgba(255, 99, 132, 1)",
					"rgba(75, 192, 192, 1)",
					"rgba(54, 162, 235, 1)",
					"rgba(255, 206, 86, 1)",
					"rgba(153, 102, 255, 1)",
					"rgba(255, 159, 64, 1)",
				],
				borderWidth: 1,
			},
		],
	};

	const options = { maintainAspectRatio: false };

	return (
		<article className="card chart-card">
			<NavLink to={serverPath + "/Analytics"}>
				<h3 className="no-break">{props.title}</h3>
			</NavLink>

			<div className="chart-wrapper">
				<Line data={data} options={options}></Line>
			</div>
		</article>
	);
}
export function UserIncomeCard(props) {
	const projects = useSelector((state) => state.projects.projects);
	const loggedIn = useSelector((state) => state.user.loggedIn);
	const serverPath = useSelector((state) => state.app.serverPath);
	const ceos = useSelector((state) => state.app.ceos);
	const settings = useSelector((state) => state.settings.settings);

	const storagePrice = settings ? settings.storagePrice : null;
	const amountOfUsers = settings?.ceos?.length || 4;

	if (!projects || !storagePrice || !loggedIn || !ceos) return null;

	const monthLabels = [
		"Januar",
		"Februar",
		"März",
		"April",
		"Mai",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember",
	];

	let monthLabelsAdjusted = [];

	const data = {
		datasets: [],
	};

	const backgroundColors = [
		"rgba(75, 192, 192, 0.2)",
		"rgba(54, 162, 235, 0.2)",
		"rgba(255, 99, 132, 0.2)",
		"rgba(255, 206, 86, 0.2)",
		"rgba(153, 102, 255, 0.2)",
		"rgba(255, 159, 64, 0.2)",
	];

	const borderColors = [
		"rgba(75, 192, 192, 1)",
		"rgba(54, 162, 235, 1)",
		"rgba(255, 99, 132, 1)",
		"rgba(255, 206, 86, 1)",
		"rgba(153, 102, 255, 1)",
		"rgba(255, 159, 64, 1)",
	];

	const chooseColor = (inUse) => {
		let index = 0;
		if (inUse.length === borderColors.length) inUse = [];
		while (inUse.includes(index)) index = Math.floor(Math.random() * Math.floor(backgroundColors.length));
		return index;
	};

	const inUse = [];

	const usernames = ceos.map((ceo) => ceo.name);

	for (let username of usernames) {
		let userIncome = [];
		for (let i = 0; i < 12; i++) {
			let dt = new Date();
			let month = dt.getMonth() - i;
			let year = dt.getFullYear();
			let monthFirst = Date.parse(new Date(year, month, 1));
			let monthLast = Date.parse(new Date(year, month + 1, 0));

			userIncome.push(
				parseFloat(
					projects.reduce((total, project) => {
						const projDate = Date.parse(new Date(project.timestamp));
						let ceoIncome =
							monthLast > projDate && projDate > monthFirst
								? ((parseFloat(project.budget) -
										parseFloat(
											project.expenses.reduce(
												(total, expense) => total + parseFloat(expense.amount),
												0
											)
										) -
										parseFloat(project.storage) * parseFloat(storagePrice)) *
										parseFloat(project.splitPercentage.ceo)) /
								  amountOfUsers
								: 0;
						return total + ceoIncome;
					}, 0) +
						projects.reduce((total, project) => {
							const projDate = Date.parse(new Date(project.timestamp));
							let coinsIncome =
								monthLast > projDate && projDate > monthFirst
									? (parseFloat(project.budget) -
											parseFloat(
												project.expenses.reduce(
													(total, expense) => total + parseFloat(expense.amount),
													0
												)
											) -
											parseFloat(project.storage) * parseFloat(storagePrice)) *
									  ((parseFloat(project.splitPercentage.coins) *
											(
												project.coins.find(
													(coin) => coin.name.toLowerCase() === username.toLowerCase()
												) || {
													amount: 0,
												}
											).amount) /
											100)
									: 0;
							return total + coinsIncome;
						}, 0)
				).toFixed(2)
			);

			if (monthLabelsAdjusted.length < 12) monthLabelsAdjusted.push(monthLabels[new Date(monthFirst).getMonth()]);
		}

		const colorIndex = chooseColor(inUse);
		inUse.push(colorIndex);

		data.datasets.push({
			label: username[0].toUpperCase() + username.slice(1),
			data: userIncome.reverse(),
			backgroundColor: backgroundColors[colorIndex],
			borderColor: borderColors[colorIndex],
			borderWidth: 1,
		});
	}

	data.labels = monthLabelsAdjusted.reverse();

	const options = { maintainAspectRatio: false };

	return (
		<article className="card chart-card">
			<NavLink to={serverPath + "/Analytics"}>
				<h3 className="no-break">{props.title}</h3>
			</NavLink>

			<div className="chart-wrapper">
				<Line data={data} options={options}></Line>
			</div>
		</article>
	);
}

export function YTDCard(props) {
	const projects = useSelector((state) => state.projects.projects);
	const expenses = useSelector((state) => state.expenses.expenses);
	const loggedIn = useSelector((state) => state.user.loggedIn);
	const serverPath = useSelector((state) => state.app.serverPath);
	const ceos = useSelector((state) => state.app.ceos);
	const settings = useSelector((state) => state.settings.settings);

	const storagePrice = settings ? settings.storagePrice : null;
	const amountOfUsers = settings?.ceos?.length || 4;

	const yearFirst = Date.parse(new Date(new Date().getFullYear(), 0, 1));
	const today = Date.parse(new Date());

	const [startDate, setStartDate] = useState(yearFirst);
	const [endDate, setEndDate] = useState(today);

	if (!projects || !storagePrice || !loggedIn || !ceos || !expenses) return null;

	const ytdSales = projects.reduce((total, project) => {
		const projDate = Date.parse(new Date(project.timestamp));
		let sales = endDate > projDate && projDate > startDate ? parseFloat(project.budget) : 0;
		return total + sales;
	}, 0);

	const ytdProjectProfit = projects.reduce((total, project) => {
		const projDate = Date.parse(new Date(project.timestamp));
		let profit =
			endDate > projDate && projDate > startDate
				? parseFloat(project.budget) -
				  parseFloat(project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0))
				: 0;
		return total + profit;
	}, 0);

	const ytdProfit =
		projects.reduce((total, project) => {
			const projDate = Date.parse(new Date(project.timestamp));
			let profit =
				endDate > projDate && projDate > startDate
					? parseFloat(project.budget) -
					  parseFloat(project.expenses.reduce((total, expense) => total + parseFloat(expense.amount), 0))
					: 0;
			return total + profit;
		}, 0) -
		expenses.reduce((total, expense) => {
			const projDate = Date.parse(new Date(expense.timestamp));
			let amount = endDate > projDate && projDate > startDate ? parseFloat(expense.amount) : 0;
			return total + amount;
		}, 0);

	const usernames = ceos.map((ceo) => ceo.name);
	let userIncomes = [];

	for (let username of usernames) {
		userIncomes.push({
			name: username,
			income: parseFloat(
				projects.reduce((total, project) => {
					const projDate = Date.parse(new Date(project.timestamp));
					let ceoIncome =
						endDate > projDate && projDate > startDate
							? ((parseFloat(project.budget) -
									parseFloat(
										project.expenses.reduce(
											(total, expense) => total + parseFloat(expense.amount),
											0
										)
									) -
									parseFloat(project.storage) * parseFloat(storagePrice)) *
									parseFloat(project.splitPercentage.ceo)) /
							  amountOfUsers
							: 0;
					return total + ceoIncome;
				}, 0) +
					projects.reduce((total, project) => {
						const projDate = Date.parse(new Date(project.timestamp));
						let coinsIncome =
							endDate > projDate && projDate > startDate
								? (parseFloat(project.budget) -
										parseFloat(
											project.expenses.reduce(
												(total, expense) => total + parseFloat(expense.amount),
												0
											)
										) -
										parseFloat(project.storage) * parseFloat(storagePrice)) *
								  ((parseFloat(project.splitPercentage.coins) *
										(
											project.coins.find(
												(coin) => coin.name.toLowerCase() === username.toLowerCase()
											) || {
												amount: 0,
											}
										).amount) /
										100)
								: 0;
						return total + coinsIncome;
					}, 0)
			).toFixed(2),
		});
	}

	return (
		<article className="card">
			<section>
				<label htmlFor="startDate">Start</label>
				<ReactDatePicker
					name="startDate"
					selected={new Date(startDate)}
					dateFormat="dd.MM.yyyy, HH:mm"
					showTimeInput
					onChange={(date) => setStartDate(date)}
					required
				/>

				<label htmlFor="endDate">Ende</label>
				<ReactDatePicker
					name="endDate"
					selected={new Date(endDate)}
					dateFormat="dd.MM.yyyy, HH:mm"
					showTimeInput
					onChange={(date) => setEndDate(date)}
					required
				/>
			</section>
			<div className="flex-row flex-start justify-content-center">
				<section>
					<table className="table table-layout-fixed">
						<thead key={0}>
							<tr key={0}>
								<td key={0}>
									<NavLink to={serverPath + "/Analytics"}>
										<h3 className="no-break">Year to Date {new Date().getFullYear()}</h3>
									</NavLink>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr key={ytdSales}>
								<td>silkrock YTD Umsatz:</td>
								<td className="green bold">{parseFloat(ytdSales).toFixed(2)}</td>
							</tr>
							<tr key={ytdProjectProfit}>
								<td>silkrock YTD Projekt Profit:</td>
								<td className="green bold">{parseFloat(ytdProjectProfit).toFixed(2)}</td>
							</tr>
							<tr key={ytdProfit}>
								<td>silkrock YTD Profit:</td>
								<td className="green bold">{parseFloat(ytdProfit).toFixed(2)}</td>
							</tr>
						</tbody>
					</table>
				</section>

				<section>
					<table className="table table-layout-fixed">
						<thead key={1}>
							<tr>
								<td>
									<h3 className="no-break">Nutzer Einkommen</h3>
								</td>
							</tr>
						</thead>
						<tbody>
							{userIncomes.map((userIncome) => {
								return (
									<tr key={userIncome.name + String(userIncome.income)}>
										<td>
											{userIncome.name[0].toUpperCase() + userIncome.name.slice(1)}s YTD
											Einkommen:
										</td>
										<td className="green bold">{userIncome.income}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</section>
			</div>
		</article>
	);
}

// function SilkrockIncomeChart(props) {
// 	return <></>;
// }

import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import url from "url";

import getFromAPI from "../scripts/getFromAPI";

export default function APILoader(props) {
	const dispatch = useDispatch();

	const accessToken = useSelector((state) => state.user.accessToken);

	// loading the different actions for the datapoints which are used by the getFromAPI fn
	const dataNames = useSelector((state) => state.app.dataNames);
	const loadingActions = useCallback(
		useSelector((state) => {
			let loadingActions = {};

			for (let dataName of dataNames) {
				loadingActions[dataName] = state[dataName].loadingActions;
			}

			return loadingActions;
		}),
		[dataNames]
	);

	// loading the different apiEndpoints which are used by the getFromAPI fn
	const apiUrl = useSelector((state) => state.app.apiUrl);
	const apiEndpoints = useCallback(
		useSelector((state) => {
			let apiEndpoints = {};

			for (let dataName of dataNames) {
				apiEndpoints[dataName] = state[dataName].apiEndpoint;
			}

			return apiEndpoints;
		}),
		[dataNames]
	);

	// actually loading the data and dispatching the actions the associated actions
	React.useEffect(() => {
		dataNames.map((dataName) => {
			const dataUrl = url.resolve(apiUrl, apiEndpoints[dataName]);

			const dataLoadingActions = loadingActions[dataName];

			return getFromAPI(dispatch, dataUrl, accessToken, dataLoadingActions, (res) => {});
		});
	}, [dispatch, apiUrl, dataNames, accessToken, loadingActions, apiEndpoints]);

	return null;
}

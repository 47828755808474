import { useSelector } from "react-redux";

export default function useCalcTaxes(taxYear) {
	const projects = useSelector((state) => state.projects.projects);
	const expenses = useSelector((state) => state.expenses.expenses);
	const payments = useSelector((state) => state.payments.payments);
	const loggedIn = useSelector((state) => state.user.loggedIn);
	const ceos = useSelector((state) => state.app.ceos);
	const settings = useSelector((state) => state.settings.settings);

	const storagePrice = settings ? settings.storagePrice : null;

	if (!projects || !storagePrice || !loggedIn || !ceos || !expenses || !payments) return null;

	const taxYearProfit =
		projects.reduce((total, project) => {
			let profit =
				new Date(taxYear).getFullYear() === new Date(project.timestamp).getFullYear()
					? parseFloat(project.budget) -
					  parseFloat(
							project.expenses.reduce(
								(total, expense) => total + (expense.deductable ? parseFloat(expense.amount) : 0),
								0
							)
					  )
					: 0;
			return total + profit;
		}, 0) -
		payments.reduce((total, payment) => {
			return (
				total +
				(new Date(taxYear).getFullYear() === new Date(payment.timestamp).getFullYear() && payment.deductable
					? parseFloat(payment.amount)
					: 0)
			);
		}, 0) -
		expenses.reduce((total, expense) => {
			let amount =
				new Date(taxYear).getFullYear() === new Date(expense.timestamp).getFullYear() && expense.deductable
					? parseFloat(expense.amount)
					: 0;
			return total + amount;
		}, 0);

	let taxAmount = 0;
	if (taxYearProfit < 9409) {
		taxAmount = 0;
	} else if (taxYearProfit < 1533) {
		const y = (taxYearProfit - 9408) / 10000;
		taxAmount = (972.87 * y + 1400) * y;
	} else if (taxYearProfit < 57052) {
		const z = (taxYearProfit - 14532) / 10000;
		taxAmount = (212.02 * z + 2397) * z + 972.79;
	} else if (taxYearProfit < 270500) {
		taxAmount = 0.42 * taxYearProfit - 8963.74;
	} else if (taxYearProfit > 270500) {
		taxAmount = 0.45 * taxYearProfit - 17078.74;
	}

	return taxAmount;
}

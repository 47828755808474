import React from "react";
import { useSelector } from "react-redux";

import { Switch, Route, NavLink } from "react-router-dom";

import ProjectDetailCard from "./ProjectDetailCard";
import LoadingSpinner from "./LoadingSpinner";

export default function TableWrapper(props) {
	const dataName = props.dataName;
	const serverPath = useSelector((state) => state.app.serverPath);
	const error = useSelector((state) => state[dataName].error);
	return (
		<>
			<Switch>
				<Route path={serverPath + "/projects/:id"} component={ProjectDetailCard} />
				<>
					<article className={"card " + dataName}>
						{error && <span className="red">LoadingError: {JSON.stringify(error)}</span>}
						<DataTable {...props} />
					</article>
				</>
			</Switch>

			{InputCard && <InputCard>{props.InputCard}</InputCard>}
		</>
	);
}

function DataTable(props) {
	const dataName = props.dataName;
	const DataRow = props.dataRow;
	const title = props.title;
	const loggedIn = useSelector((state) => state.user.loggedIn);
	const data = useSelector((state) => state[dataName][dataName]);
	const dataFetchPending = useSelector((state) => state[dataName].fetchPending);
	const serverPath = useSelector((state) => state.app.serverPath);

	if (dataFetchPending) return <LoadingSpinner />;
	if (!loggedIn || !data) return null;

	return (
		<table className="table">
			<thead key={0}>
				<tr>
					<td>
						<NavLink to={serverPath + "/" + dataName}>
							<h3>{title}</h3>
						</NavLink>
					</td>
				</tr>
			</thead>
			<tbody key={data}>
				{data.slice(0, props.maxLength).map((element) => {
					return <DataRow key={element.id} {...element} />;
				})}
			</tbody>
		</table>
	);
}

function InputCard(props) {
	if (!props.children) return null;

	return <div className="card input-card">{props.children}</div>;
}

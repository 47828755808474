import {
	fetchExpensesError,
	fetchExpensesPending,
	fetchExpensesSuccess,
	expensesReloadNeeded,
} from "../actions/ExpensesActions";

const initialState = {
	anyPending: false,
	fetchPending: false,
	postPending: false,
	deletePending: false,
	editPending: false,
	expenses: null,
	error: null,
	reloadNeeded: false,
	apiEndpoint: "expenses",
	loadingActions: {
		before: [fetchExpensesPending, expensesReloadNeeded],
		success: [fetchExpensesSuccess],
		error: [fetchExpensesError],
	},
};

export const expensesReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_EXPENSES_PENDING":
			return {
				...state,
				anyPending: true,
				fetchPending: true,
			};
		case "FETCH_EXPENSES_SUCCESS":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				expenses: action.payload.reverse(),
			};
		case "FETCH_EXPENSES_ERROR":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				error: action.error,
			};
		case "POST_EXPENSES_PENDING":
			return {
				...state,
				anyPending: true,
				postPending: true,
			};
		case "POST_EXPENSES_SUCCESS":
			return {
				...state,
				anyPending: false,
				postPending: false,
				expenses: [action.payload].concat(state.expenses),
			};
		case "POST_EXPENSES_ERROR":
			return {
				...state,
				anyPending: false,
				postPending: false,
				error: action.error,
			};
		case "EDIT_EXPENSES_PENDING":
			return {
				...state,
				anyPending: true,
				editPending: true,
			};
		case "EDIT_EXPENSES_SUCCESS":
			return {
				...state,
				anyPending: false,
				editPending: false,
				expenses: state.expenses.map((expense) => {
					if (expense.id === action.payload.id) return action.payload;

					return expense;
				}),
			};
		case "EDIT_EXPENSES_ERROR":
			return {
				...state,
				anyPending: false,
				editPending: false,
				error: action.error,
			};
		case "DELETE_EXPENSES_PENDING":
			return {
				...state,
				anyPending: true,
				deletePending: true,
			};
		case "DELETE_EXPENSES_SUCCESS":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				expenses: state.expenses.filter((expense) => expense.id !== action.id),
			};
		case "DELETE_EXPENSES_ERROR":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				error: action.error,
			};
		case "EXPENSES_RELOAD_NEEDED":
			return {
				...state,
				// reloadNeeded: action.reloadNeeded,
			};
		default:
			return state;
	}
};

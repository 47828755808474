import {
	fetchProjectsError,
	fetchProjectsPending,
	fetchProjectsSuccess,
	projectsReloadNeeded,
} from "../actions/ProjectsActions";

const initialState = {
	anyPending: false,
	fetchPending: false,
	deletePending: false,
	editPending: false,
	postPending: false,
	projects: null,
	error: null,
	reloadNeeded: false,
	apiEndpoint: "projects",
	loadingActions: {
		before: [fetchProjectsPending, projectsReloadNeeded],
		success: [fetchProjectsSuccess],
		error: [fetchProjectsError],
	},
};

export const projectsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_PROJECTS_PENDING":
			return {
				...state,
				anyPending: true,
				fetchPending: true,
			};
		case "FETCH_PROJECTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				error: null,
				projects: action.payload.reverse(),
			};
		case "FETCH_PROJECTS_ERROR":
			return {
				...state,
				anyPending: false,
				fetchPending: false,
				error: action.error,
			};
		case "POST_PROJECTS_PENDING":
			return {
				...state,
				anyPending: true,
				postPending: true,
			};
		case "POST_PROJECTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				postPending: false,
				projects: [action.payload].concat(state.projects),
			};
		case "POST_PROJECTS_ERROR":
			return {
				...state,
				anyPending: false,
				postPending: false,
				error: action.error,
			};
		case "EDIT_PROJECTS_PENDING":
			return {
				...state,
				anyPending: true,
				editPending: true,
			};
		case "EDIT_PROJECTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				editPending: false,
				projects: state.projects.map((project) => {
					if (project.id === action.payload.id) return action.payload;

					return project;
				}),
			};
		case "EDIT_PROJECTS_ERROR":
			return {
				...state,
				anyPending: false,
				editPending: false,
				error: action.error,
			};
		case "DELETE_PROJECTS_PENDING":
			return {
				...state,
				anyPending: true,
				deletePending: true,
			};
		case "DELETE_PROJECTS_SUCCESS":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				projects: state.projects.filter((project) => project.id !== action.id),
			};
		case "DELETE_PROJECTS_ERROR":
			return {
				...state,
				anyPending: false,
				deletePending: false,
				error: action.error,
			};
		case "PROJECTS_RELOAD_NEEDED":
			return {
				...state,
				// reloadNeeded: action.reloadNeeded,
			};
		default:
			return state;
	}
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import postUserLogin from "../scripts/userLoginScript";

import LoadingSpinner from "./LoadingSpinner";

export default function UserLogin(props) {
	const dispatch = useDispatch();

	const pending = useSelector((state) => state.user.pending);

	const apiUrl = useSelector((state) => state.app.apiUrl);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors] = useState({ password: "", email: "" });

	const handleSubmit = (event) => {
		event.preventDefault();
		postUserLogin(dispatch, email, password, apiUrl);
	};

	let disabled = pending;

	function handleChange(event) {
		event.preventDefault();
		const { name, value } = event.target;

		switch (name) {
			case "email":
				setEmail(value);
				break;
			case "password":
				setPassword(value);
				break;
			default:
				break;
		}
	}

	return (
		<div className="card user-login">
			{pending && <LoadingSpinner />}
			<h4>Login, please..</h4>
			<form onSubmit={handleSubmit}>
				<label htmlFor="email">E-Mail</label>
				<input
					name="email"
					className={errors.email.length > 0 ? "input-error" : ""}
					id="inpLoginName"
					type="email"
					placeholder="xxx@iii.to"
					disabled={disabled}
					value={email}
					onChange={handleChange}
					autoFocus
					required
				/>

				<label htmlFor="password">Passwort</label>
				<input
					name="password"
					className={errors.password.length > 0 ? "input-error" : ""}
					id="inpLoginPassword"
					type="password"
					disabled={disabled}
					value={password}
					onChange={handleChange}
					required
				/>
				<button disabled={disabled} className="btn btn-primary" type="submit">
					Login
				</button>
			</form>
		</div>
	);
}
